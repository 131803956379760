import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import {
  apiConversationsRequest,
  apiDeleteProfileRequest,
  apiForgotPassRequest,
  apiIndustriesRequest,
  apiLoginRequest,
  apiPositionsRequest,
  apiReadMessageRequest,
  apiResetPasswordRequest,
  apiSendMessageRequest,
  apiSignupRequest,
  apiSocialAuthRequest,
  apiStartConversationsRequest,
  apiUpdateConversationsRequest,
  apiUpdateProfileRequest,
  apiUserRequest,
  apiUserSearchByIdsRequest
} from './authAPI'

// eslint-disable-next-line no-unused-vars
const mockedState = {
  token: 'c42064ed451b0e1136de83e908584e7d0e1b753e',
  user: {
    id: 2,
    first_name: 'Andre',
    last_name: 'Saboia',
    email: 'andre.saboia@crowdbotics.com',
    type: 'Candidate',
    candidate: {
      resume: null,
      currently_employed: null,
      current_company_name: null,
      current_company_industry: null,
      current_company_position: null,
      contact_number: '4444999494',
      photo: null,
      city: null,
      zip_code: null,
      state: null,
      video: null,
      resume_file: null
    }
  }
}

export const authInitialState = {
  status: 'idle',
  user: {},
  userProfile: {},
  userCurrentProfile: {},
  token: null,
  positions: [],
  industries: [],
  conversations: null
}

export const storeToken = key => token =>
  localStorage.setItem(key, JSON.stringify(token))

export const retriveToken = key => JSON.parse(localStorage.getItem(key))
export const removeToken = key => localStorage.removeItem(key)

export const socialAuthRequest = createAsyncThunk(
  'auth/socialAuthRequest',
  async (payload, { rejectWithValue }) => {
    try {
      const { data, status } = await apiSocialAuthRequest(payload)()

      if (status === 201 || status === 200) {
        storeToken('token')(data?.token)
        storeToken('id')(data?.user?.id)
        return data
      }

      // data examples:
      // success: {"token":"c42064ed451b0e1136de83e908584e7d0e1b753e","user":{"id":2,"first_name":"Andre","last_name":"Saboia","email":"andre.saboia@crowdbotics.com","type":"Candidate","candidate":{"resume":null,"currently_employed":null,"current_company_name":null,"current_company_industry":null,"current_company_position":null,"contact_number":"4444999494","photo":null,"city":null,"zip_code":null,"state":null,"video":null,"resume_file":null}}}
      // error: {"type":["This field is required."]}
      return rejectWithValue(data)
    } catch (err) {
      console.log('[dev] auth/socialAuthRequest::response::catch', err)
    }
  }
)

export const signupRequest = createAsyncThunk(
  'auth/signupRequest',
  async (payload, { rejectWithValue }) => {
    try {
      const { data, status } = await apiSignupRequest(payload)()

      if (status === 201 || status === 200) {
        storeToken('token')(data?.token)
        storeToken('id')(data?.user?.id)
        return data
      }

      // data examples:
      // success: {"token":"c42064ed451b0e1136de83e908584e7d0e1b753e","user":{"id":2,"first_name":"Andre","last_name":"Saboia","email":"andre.saboia@crowdbotics.com","type":"Candidate","candidate":{"resume":null,"currently_employed":null,"current_company_name":null,"current_company_industry":null,"current_company_position":null,"contact_number":"4444999494","photo":null,"city":null,"zip_code":null,"state":null,"video":null,"resume_file":null}}}
      // error: {"type":["This field is required."]}
      return rejectWithValue(data)
    } catch (err) {
      console.log('[dev] auth/signupRequest::response::catch', err)
    }
  }
)

export const loginRequest = createAsyncThunk(
  'auth/loginRequest',
  async (payload, { rejectWithValue }) => {
    try {
      const { data, status } = await apiLoginRequest(payload)()

      if (status === 201 || status === 200) {
        storeToken('token')(data?.token)
        storeToken('id')(data?.user?.id)
        // storeToken('user')(data?.user);
        return data
      }

      return rejectWithValue(data)
    } catch (err) {
      console.log('[dev] login/loginRequest::response::catch', err)
      // return rejectWithValue(err);
    }
  }
)

export const forgotPassRequest = createAsyncThunk(
  'auth/forgotPassRequest',
  async (payload, { rejectWithValue }) => {
    try {
      const { data, status } = await apiForgotPassRequest(payload)()

      if (status === 201 || status === 200) {
        return data
      }

      return rejectWithValue(data)
    } catch (err) {
      console.log('[dev] login/loginRequest::response::catch', err)
      // return rejectWithValue(err);
    }
  }
)

export const resetPasswordRequest = createAsyncThunk(
  'auth/resetPasswordRequest',
  async ({ payload, token }, { rejectWithValue }) => {
    try {
      console.log('payload, token', payload, token)
      const { data, status } = await apiResetPasswordRequest(payload)(token)

      if (status === 201 || status === 200) {
        return data
      }

      return rejectWithValue(data)
    } catch (err) {
      console.log('[dev] login/loginRequest::response::catch', err)
      // return rejectWithValue(err);
    }
  }
)

export const userProfileRequest = createAsyncThunk(
  'auth/userProfileRequest',
  async (id, { getState, rejectWithValue }) => {
    try {
      const { token } = getState().auth
      const { data, status } = await apiUserRequest(id)(token)
      if (status === 201 || status === 200) {
        return data
      }
      return rejectWithValue(data)
    } catch (err) {
      console.log('[dev] auth/userProfileRequest::response::catch', err)
    }
  }
)

export const currentProfileRequest = createAsyncThunk(
  'auth/currentProfileRequest',
  async (id, { getState, rejectWithValue }) => {
    try {
      const { token } = getState().auth
      const { data, status } = await apiUserRequest(id)(token)
      if (status === 201 || status === 200) {
        return data
      }
      return rejectWithValue(data)
    } catch (err) {
      console.log('[dev] auth/userProfileRequest::response::catch', err)
    }
  }
)

export const userDeleteProfileRequest = createAsyncThunk(
  'auth/userDeleteProfileRequest',
  async (body, { getState, rejectWithValue }) => {
    try {
      const { token, user } = getState().auth
      const { data, status } = await apiDeleteProfileRequest({ id: user?.id })(
        token
      )
      if (status === 201 || status === 200 || status === 204) {
        return data
      }
      return rejectWithValue(data)
    } catch (err) {
      console.log('[dev] auth/userDeleteProfileRequest::response::catch', err)
    }
  }
)

export const logoutRequest = createAsyncThunk(
  'auth/logoutRequest',
  async payload => {
    removeToken('token')
  }
)

export const positionsRequest = createAsyncThunk(
  'auth/positionsRequest',
  async (body, { rejectWithValue }) => {
    try {
      const { data, status } = await apiPositionsRequest()()

      if (status === 201 || status === 200) {
        return data
      }

      return rejectWithValue(data)
    } catch (err) {
      console.log('[dev] auth/positionsRequest::response::catch', err)
    }
  }
)

export const industriesRequest = createAsyncThunk(
  'auth/industriesRequest',
  async (body, { rejectWithValue }) => {
    try {
      const { data, status } = await apiIndustriesRequest()()

      if (status === 201 || status === 200) {
        return data
      }

      return rejectWithValue(data)
    } catch (err) {
      console.log('[dev] auth/industriesRequest::response::catch', err)
    }
  }
)

export const conversationsRequest = createAsyncThunk(
  'auth/conversationsRequest',
  async (body, { getState, rejectWithValue }) => {
    try {
      const { token } = getState().auth
      const { data, status } = await apiConversationsRequest()(token)

      if (status === 201 || status === 200) {
        return data
      }

      return rejectWithValue(data)
    } catch (err) {
      console.log('[dev] auth/conversationsRequest::response::catch', err)
    }
  }
)

export const userSearchByIdsRequest = createAsyncThunk(
  'auth/userSearchByIdsRequest',
  async (ids, { getState, rejectWithValue }) => {
    try {
      const { token } = getState().auth
      const { data, status } = await apiUserSearchByIdsRequest(ids)(token)

      if (status === 201 || status === 200) {
        return data
      }

      return rejectWithValue(data)
    } catch (err) {
      console.log('[dev] auth/conversationsRequest::response::catch', err)
    }
  }
)

export const startConversationsRequest = createAsyncThunk(
  'auth/startConversationsRequest',
  async (body, { getState, rejectWithValue }) => {
    try {
      const { token } = getState().auth
      const { data, status } = await apiStartConversationsRequest(body)(token)

      if (status === 201 || status === 200) {
        return data
      }

      return rejectWithValue(data)
    } catch (err) {
      console.log('[dev] auth/startConversationsRequest::response::catch', err)
    }
  }
)

export const updateConversationsRequest = createAsyncThunk(
  'auth/updateConversationsRequest',
  async (body, { getState, rejectWithValue }) => {
    try {
      const { token } = getState().auth
      const { payload, id } = body
      const { data, status } = await apiUpdateConversationsRequest(
        id,
        payload
      )(token)

      if (status === 201 || status === 200) {
        return data
      }

      return rejectWithValue(data)
    } catch (err) {
      console.log('[dev] auth/startConversationsRequest::response::catch', err)
    }
  }
)

export const sendMessageRequest = createAsyncThunk(
  'auth/sendMessageRequest',
  async (body, { getState, rejectWithValue }) => {
    try {
      const { token } = getState().auth
      const { data, status } = await apiSendMessageRequest(body)(token)

      if (status === 201 || status === 200) {
        return data
      }

      return rejectWithValue(data)
    } catch (err) {
      console.log('[dev] auth/startConversationsRequest::response::catch', err)
    }
  }
)

export const readMessageRequest = createAsyncThunk(
  'auth/readMessageRequest',
  async (body, { getState, rejectWithValue }) => {
    try {
      const { token } = getState().auth
      const { data, status } = await apiReadMessageRequest(body)(token)

      if (status === 201 || status === 200) {
        return data
      }

      return rejectWithValue(data)
    } catch (err) {
      console.log('[dev] auth/startConversationsRequest::response::catch', err)
    }
  }
)

export const updateProfileRequest = createAsyncThunk(
  'auth/updateProfileRequest',
  async (body, { getState, rejectWithValue }) => {
    try {
      const { token, user } = getState().auth
      const { data, status } = await apiUpdateProfileRequest({
        body,
        id: user?.id
      })(token)

      if (status === 201 || status === 200) {
        return data
      }

      return rejectWithValue(data)
    } catch (err) {
      console.log('[dev] auth/updateProfileRequest::response::catch', err)
    }
  }
)

export const updateProfileJsonRequest = createAsyncThunk(
  'auth/updateProfileJsonRequest',
  async (body, { getState, rejectWithValue }) => {
    try {
      const { token, user } = getState().auth
      const { data, status } = await apiUpdateProfileRequest({
        body,
        id: user?.id,
        type: 'application/json'
      })(token)

      if (status === 201 || status === 200) {
        return data
      }

      return rejectWithValue(data)
    } catch (err) {
      console.log('[dev] auth/updateProfileJsonRequest::response::catch', err)
    }
  }
)

export const authSlice = initialState =>
  createSlice({
    name: 'auth',
    initialState,
    reducers: {},
    extraReducers: builder => {
      builder
        .addCase(socialAuthRequest.pending, state => {
          state.status = 'loading'
        })
        .addCase(socialAuthRequest.fulfilled, (state, action) => {
          const { user, token } = action.payload
          state.user = user
          state.token = token
          state.status = 'idle'
        })
        .addCase(signupRequest.pending, state => {
          state.status = 'loading'
        })
        .addCase(signupRequest.fulfilled, (state, action) => {
          const { user, token } = action.payload
          state.user = user
          state.token = token
          state.status = 'idle'
        })
        .addCase(loginRequest.pending, state => {
          state.status = 'loading'
        })
        .addCase(loginRequest.fulfilled, (state, action) => {
          const { user, token } = action.payload
          state.user = user
          state.token = token
          state.status = 'idle'
        })
        .addCase(conversationsRequest.pending, state => {
          state.status = 'loading'
        })
        .addCase(conversationsRequest.fulfilled, (state, action) => {
          state.conversations = action.payload
          state.status = 'idle'
        })
        .addCase(userSearchByIdsRequest.pending, state => {
          state.status = 'loading'
        })
        .addCase(userSearchByIdsRequest.fulfilled, (state, action) => {
          state.status = 'idle'
        })
        .addCase(startConversationsRequest.pending, state => {
          state.status = 'loading'
        })
        .addCase(startConversationsRequest.fulfilled, state => {
          state.status = 'idle'
        })
        .addCase(updateConversationsRequest.pending, state => {
          state.status = 'loading'
        })
        .addCase(updateConversationsRequest.fulfilled, state => {
          state.status = 'idle'
        })
        .addCase(sendMessageRequest.pending, state => {
          state.status = 'loading'
        })
        .addCase(sendMessageRequest.fulfilled, state => {
          state.status = 'idle'
        })
        .addCase(readMessageRequest.pending, state => {
          state.status = 'loading'
        })
        .addCase(readMessageRequest.fulfilled, state => {
          state.status = 'idle'
        })
        .addCase(forgotPassRequest.pending, state => {
          state.status = 'loading'
        })
        .addCase(forgotPassRequest.fulfilled, state => {
          state.status = 'idle'
        })
        .addCase(resetPasswordRequest.pending, state => {
          state.status = 'loading'
        })
        .addCase(resetPasswordRequest.fulfilled, state => {
          state.status = 'idle'
        })
        .addCase(userProfileRequest.pending, state => {
          state.status = 'loading'
        })
        .addCase(userProfileRequest.fulfilled, (state, action) => {
          state.userProfile = action.payload
          state.status = 'idle'
        })
        .addCase(currentProfileRequest.pending, state => {
          state.status = 'loading'
        })
        .addCase(currentProfileRequest.fulfilled, (state, action) => {
          state.userCurrentProfile = action.payload
          state.status = 'idle'
        })
        .addCase(userDeleteProfileRequest.pending, state => {
          state.status = 'loading'
        })
        .addCase(userDeleteProfileRequest.fulfilled, state => {
          state.status = 'idle'
        })
        .addCase(logoutRequest.fulfilled, (state, action) => {
          state.user = {}
          state.token = null
        })
        .addCase(updateProfileRequest.fulfilled, (state, action) => {
          const user = action.payload
          state.user = user
          state.status = 'idle'
        })
        .addCase(updateProfileJsonRequest.fulfilled, (state, action) => {
          state.user = action.payload
          state.status = 'idle'
        })
        .addCase(positionsRequest.fulfilled, (state, action) => {
          state.positions = action.payload
          state.status = 'idle'
        })
        .addCase(industriesRequest.fulfilled, (state, action) => {
          state.industries = action.payload
          state.status = 'idle'
        })
    }
  })

export const selectUser = state => state.auth.user
export const selectCurrentUser = state => state.auth.userCurrentProfile
export const selectConversations = state => state.auth.conversations
export const selectUserProfile = state => state.auth.userProfile
export const selectIndustries = state => state.auth.industries
export const selectPositions = state => state.auth.positions
export const selectToken = state => state.auth.token
export const selectIsLoadingAuth = state => state.job.status === 'loading'

export default authSlice.reducer
