import * as React from 'react';
import { useNavigate } from 'react-router-dom';

import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

import { ReactComponent as PlusFilledIcon } from '@assets/icons/plusfilled.svg';
import { ReactComponent as SadFaceIcon } from '@assets/icons/sadface.svg';
import UIButton from '@components/UIButton';
import UICard from '@components/UICard';
import { selectCurrentUser } from '@features/auth/authSlice';
import { useSelector } from 'react-redux';

export const CreateJobButton = () => {
  const navigate = useNavigate();
  const user = useSelector(selectCurrentUser)
  return (
    <UIButton
      color="secondary"
      sx={{
        //
        color: 'white',
        my: 1,
        py: 1,
      }}
      disabled={user?.subscribed == 'canceled' || !user?.subscription}
      startIcon={<PlusFilledIcon />}
      onClick={() => navigate('/app/jobs/create')}
    >
      Create a job post
    </UIButton>
  );
};

const PageEmptyJob = () => {
  return (
    <UICard
      sx={{
        //
        flexGrow: 1,
        // background: 'pink',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <Box
        sx={{
          flex: 1,
          display: 'flex',
          alignItems: 'center',
          flexDirection: 'column',
          justifyContent: 'center',
          mt: 3,
        }}
      >
        <SadFaceIcon />
        <Typography
          sx={{
            fontWeight: '500',
            fontSize: '1.75rem',
            textAlign: 'center',
            my: 3,
          }}
        >
          Currently you have no job posts yet.
          <br />
          Let’s create now!
        </Typography>
        <CreateJobButton />
      </Box>
    </UICard>
  );
};

export default PageEmptyJob;
