import * as React from 'react'
import UIButton from '@components/UIButton'
import {
  Divider,
  Grid,
  Paper,
  Typography,
  CircularProgress
} from '@mui/material'
import { useNavigate, useParams } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import WrapperContent from '@components/WrapperContent'
import { _deletePayMethod } from '@features/price/priceSlice'
import { useState } from 'react'
import UITextField from '@components/UITextField'
import {
  addEmployee,
  getEmployee,
  getEmployeeByID,
  updateEmployee
} from '@features/employee/employeeSlice'
import { updateSnack } from '@features/util/utilSlice'

const AddUser = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { id } = useParams()
  const [state, setState] = useState({
    first_name: '',
    last_name: '',
    email: '',
    password: '',
    loadingDelete: false,
    loading: false
  })
  const {
    first_name,
    last_name,
    email,
    password,
    loadingDelete,
    loading
  } = state

  const handleChange = (key, value) => {
    setState(pre => ({ ...pre, [key]: value }))
  }

  React.useEffect(async () => {
    if (id) {
      const res = await dispatch(getEmployeeByID(id))
      handleChange('first_name', res.payload?.first_name)
      handleChange('last_name', res.payload?.last_name)
      handleChange('email', res.payload?.email)
    }
  }, [dispatch, id])

  const _handleCreate = async () => {
    try {
      handleChange('loading', true)
      const payload = {
        first_name,
        last_name,
        email,
        password
      }
      const res = await dispatch(addEmployee(payload))
      console.log('res', res?.status)
      if (res?.payload?.status === 201 || res?.payload?.status === 200) {
        handleChange('loading', false)
        dispatch(getEmployee())
        dispatch(
          updateSnack({
            open: true,
            severity: 'success',
            message: 'User Added'
          })
        )
        navigate('/app/price')
      } else {
        console.log('res', res?.payload?.detail)
        handleChange('loading', false)
        dispatch(
          updateSnack({
            open: true,
            severity: 'error',
            message: res?.payload?.detail || 'Something went wrong!'
          })
        )
      }
    } catch (error) {
      handleChange('loading', false)
      const errorText = Object.values(error?.response?.data)
      alert(`Error: ${errorText}`)
    }
  }

  const _handleUpdate = async () => {
    try {
      handleChange('loading', true)
      const body = {
        first_name,
        last_name
      }
      password ? (body.password = password) : ''
      const res = await dispatch(updateEmployee({ id, body }))
      console.log('res', res?.status)
      if (res?.payload?.status === 201 || res?.payload?.status === 200) {
        handleChange('loading', false)
        dispatch(getEmployee())
        dispatch(
          updateSnack({
            open: true,
            severity: 'success',
            message: 'User Updated'
          })
        )
        navigate('/app/price')
      } else {
        handleChange('loading', false)
        dispatch(
          updateSnack({
            open: true,
            severity: 'error',
            message: res?.payload?.detail || 'Something went wrong!'
          })
        )
      }
    } catch (error) {
      handleChange('loading', false)
      const errorText = Object.values(error?.response?.data)
      alert(`Error: ${errorText}`)
    }
  }

  return (
    <WrapperContent
      label='Add User'
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column'
      }}
    >
      <Paper elevation={0} sx={{ width: '100%', mb: 5, p: 3, borderRadius: 5 }}>
        <Typography variant='h5'>User Info</Typography>
        <Divider sx={{ mt: 2, mb: 2 }} />
        <Grid container item xs={6}>
          <Grid container spacing={2} item xs={12}>
            <Grid item sx={{ mt: 2 }} xs={12} md={6}>
              <UITextField
                label={'First Name'}
                value={first_name}
                onChange={value => handleChange('first_name', value)}
              />
            </Grid>
            <Grid item sx={{ mt: 2 }} xs={12} md={6}>
              <UITextField
                label={'Last Name'}
                value={last_name}
                onChange={value => handleChange('last_name', value)}
              />
            </Grid>
          </Grid>
          <Grid item sx={{ mt: 2 }} xs={12}>
            <UITextField
              label={'Enter User’s Email Address'}
              value={email}
              disabled={id}
              onChange={value => handleChange('email', value)}
            />
          </Grid>
          <Grid item sx={{ mt: 2 }} xs={12}>
            <UITextField
              label={'Enter User’s Temporary Password'}
              value={password}
              onChange={value => handleChange('password', value)}
            />
          </Grid>
        </Grid>
      </Paper>
      <Grid container sx={{ mb: 3 }}>
        <UIButton
          onClick={id ? _handleUpdate : _handleCreate}
          disabled={
            id ? false : !email || !first_name || !last_name || !password
          }
          sx={{
            width: '150px',
            height: 50,
            my: 1,
            backgroundColor: 'rgba(30, 33, 32, 1)'
          }}
        >
          {loading ? (
            <CircularProgress
              style={{ color: '#fff', width: 20, height: 20 }}
            />
          ) : (
            <>{id ? 'Edit User' : 'Create a User'}</>
          )}
        </UIButton>
      </Grid>
    </WrapperContent>
  )
}
export default AddUser
