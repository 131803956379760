import * as React from 'react'
import UIButton, {
  SocialButtonFacebook,
  SocialButtonGmail
} from '@components/UIButton'
import { useNavigate, useParams, NavLink } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { unwrapResult } from '@reduxjs/toolkit'

import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import { styled } from '@mui/material/styles'
import Typography from '@mui/material/Typography'
import Button from '@mui/material/Button'
import Box from '@mui/material/Box'
import Input from '@mui/material/Input'
import InputLabel from '@mui/material/InputLabel'
import InputAdornment from '@mui/material/InputAdornment'
import OutlinedInput from '@mui/material/OutlinedInput'
import IconButton from '@mui/material/IconButton'
import FormControl from '@mui/material/FormControl'
import TextField from '@mui/material/TextField'
import AccountCircle from '@mui/icons-material/AccountCircle'
// import Divider from '@mui/material/Divider';
import DeleteIcon from '@mui/icons-material/Delete'
import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import DialogContent from '@mui/material/DialogContent'
import DialogActions from '@mui/material/DialogActions'
import CloseIcon from '@mui/icons-material/Close'
import UITextField, { UITextFieldMask } from '@components/UITextField'

import { ReactComponent as ProfileIcon } from '@assets/icons/profile.svg'
import { ReactComponent as MessageIcon } from '@assets/icons/message.svg'
import { ReactComponent as LockIcon } from '@assets/icons/lock.svg'
import { ReactComponent as CallIcon } from '@assets/icons/call.svg'

import { ReactComponent as GoogleIcon } from '@assets/icons/google.svg'
import { ReactComponent as FacebookIcon } from '@assets/icons/facebook.svg'
import {
  forgotPassRequest,
  loginRequest,
  socialAuthRequest,
  userProfileRequest
} from '@features/auth/authSlice'
import { defaltAppPage } from './PageIndex'
import { updateSnack } from '@features/util/utilSlice'
import { Config } from '@utils/common'
// import { ReactComponent as SetupProfileSvg } from '@assets/icons/imgProfile.svg';

// curl --location --request POST 'https://ideapros-llc-lander-30616.botics.co/api/v1/user/' \
// --form 'email="sallar.rezaie@crowdbotics.com"' \
// --form 'password="testpass123"' \
// --form 'first_name="Sallar"' \
// --form 'last_name="Rezaie"' \
// --form 'type="Candidate"' \
// --form 'candidate.contact_number="4169944347"'
const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2)
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1)
  }
}))
const ForgotPassDialog = props => {
  const { children, onClose, ...other } = props

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label='close'
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: theme => theme.palette.grey[500]
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  )
}
const mapType = {
  candidate: 'Candidate',
  company: 'Company'
}
const fieldValidationSchema = yup.object().shape({
  email: yup
    .string()
    .required('Please enter your email address')
    .email('Please enter a valid email address'),
  password: yup
    .string()
    .required('Please enter your password')
    .min(6)
})

const Divider = () => (
  <Box
    sx={{
      height: '1px',
      width: '120px',
      backgroundColor: '#33333320',
      mx: 5
    }}
  />
)

const defaultValues = {
  email: '',
  password: ''
}

const PageLogin = () => {
  const {
    register,
    setValue,
    handleSubmit,
    getValues,
    clearErrors,
    setError,
    formState: { errors }
  } = useForm({
    defaultValues,
    resolver: yupResolver(fieldValidationSchema)
  })
  const dispatch = useDispatch()

  const params = useParams()
  const navigate = useNavigate()
  const [open, setOpen] = React.useState(false)
  const [emailSent, setEmailSent] = React.useState(false)

  const handleClickOpen = () => {
    setOpen(true)
  }
  const handleClose = () => {
    setOpen(false)
  }

  const userType = params?.userType || 'candidate' // candidate | company

  React.useEffect(() => {
    setValue('email', defaultValues.email)
    setValue('password', defaultValues.password)
  }, [setValue])
  React.useEffect(() => {
    register('email')
    register('password')
  }, [register])

  const onChange = key => value => {
    clearErrors(key)
    setValue(key, value)
  }

  const onSubmit = () => {
    handleSubmit()
    const data = {
      email: getValues('email'),
      password: getValues('password')
    }
    const body = {
      type: mapType[userType],
      ...data
    }
    console.log('[dev] PageLogin::onSubmit::body', body)
    dispatch(loginRequest(data))
      .then(unwrapResult)
      .then(data => {
        console.log('[dev]PageLogin::data', data)
        dispatch(userProfileRequest(data?.user?.id))
        navigate(
          defaltAppPage[
            data?.user?.type === 'Employee'
              ? 'Company'
              : data?.user?.type || 'Candidate'
          ]
        )
      })
      .catch(data => {
        if (data?.detail) {
          dispatch(
            updateSnack({
              open: true,
              severity: 'error',
              message: data?.detail
            })
          )
        }
        console.log('[dev]PageLogin::catch::', data)
      })
  }

  const handleSocialLogin = user => {
    // "facebook"
    const { _token, _profile, _provider } = user || {}
    const { accessToken } = _token || {}
    const data = {
      access_token: accessToken,
      provider: _provider
      // type: mapType[userType],
    }
    console.log('[dev] handleSocialLogin::data::', data)
    dispatch(socialAuthRequest(data))
      .then(unwrapResult)
      .then(data => {
        console.log('[dev]PageLogin::data', data)
        navigate(defaltAppPage[data?.user?.type || 'Candidate'])
      })
      .catch(data => {
        if (data?.detail) {
          dispatch(
            updateSnack({
              open: true,
              severity: 'error',
              message: data?.detail
            })
          )
        }
        console.log('[dev]PageLogin::catch::', data)
      })
  }

  const handleSocialLoginFailure = err => {
    console.error('[dev] handleSocialLoginFailure::err::', err)
  }

  const handleReset = () => {
    const data = {
      email: getValues('email1')
    }
    dispatch(forgotPassRequest(data))
      .then(unwrapResult)
      .then(data => {
        console.log('[dev]PageLogin::forgotPassRequest', data)
        setEmailSent(true)
        // navigate(defaltAppPage[data?.user?.type || 'Candidate'])
      })
      .catch(data => {
        if (data?.detail) {
          dispatch(
            updateSnack({
              open: true,
              severity: 'error',
              message: data?.detail
            })
          )
        }
        console.log('[dev]PageLogin::catch::', data)
      })
  }

  console.log('getValues()', getValues())

  return (
    <Box
      sx={{
        py: 3,
        width: { sm: '543px' },
        display: 'flex',
        flexDirection: 'column'
      }}
    >
      <Typography
        sx={{
          //
          fontWeight: '500',
          fontSize: '28px',
          textAlign: 'center',
          my: 2
        }}
      >
        Login to Lander
      </Typography>
      {/* <Typography
        sx={{
          fontWeight: '500',
          fontSize: '	0.9375rem', // 15px
          color: '#00000050',
          px: 14,
          textAlign: 'center',
          pb: 2,
        }}
      >
        <NavLink
          to="/register"
          style={({ isActive }) => ({ color: '#000000' })}
        >
          Sign up
        </NavLink>
        {' here if you are not registered yet.'}
      </Typography> */}
      <Box
        component='form'
        noValidate
        sx={{
          display: 'grid',
          gridTemplateColumns: { sm: '1fr' },
          gap: 2
        }}
      >
        <UITextField
          id='email'
          label='Enter email address'
          placeholder='Enter your email address'
          icon={<MessageIcon />}
          value={getValues('email')}
          onChange={onChange('email')}
          error={errors?.email}
        />
        <UITextField
          id='password'
          label='Create Password'
          placeholder='Create Password'
          icon={<LockIcon />}
          type='password'
          onChange={onChange('password')}
          value={getValues('password')}
          error={errors?.password}
        />
      </Box>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          flexDirection: 'column',
          alignItems: 'center'
          // gridColumn: 'span 2',
        }}
      >
        <UIButton
          color='primary'
          sx={{ width: '334px', my: 4 }}
          onClick={handleSubmit(onSubmit)}
        >
          Login
        </UIButton>
        <Typography
          sx={{
            fontWeight: '500',
            fontSize: '0.875rem',
            color: '#00000050',
            px: 4,
            textAlign: 'center',
            cursor: 'pointer'
          }}
          onClick={handleClickOpen}
        >
          Forgot your password?
        </Typography>
        {/* <Box
          sx={{
            display: 'flex',
            gridColumn: 'span 2',
            alignItems: 'center',
            my: 4
          }}
        >
          <Divider />
          <Typography sx={{ opacity: 0.5 }}>Or</Typography>
          <Divider />
        </Box> */}
      </Box>
      {/* <Box
        sx={{
          display: 'grid',
          gridTemplateColumns: { sm: '1fr 1fr' },
          gap: 2
        }}
      >
        <SocialButtonFacebook
          provider='facebook'
          appId={Config.APPID_FACEBOOK}
          onLoginSuccess={handleSocialLogin}
          onLoginFailure={handleSocialLoginFailure}
          variant='outlined'
          bg='#3B72FF'
          startIcon={<FacebookIcon sx={{ alignSelf: 'flex-start' }} />}
        >
          Log in with Facebook
        </SocialButtonFacebook>
        <SocialButtonGmail
          provider='google'
          appId={Config.APPID_GOOGLE}
          onLoginSuccess={handleSocialLogin}
          onLoginFailure={handleSocialLoginFailure}
          variant='outlined'
          // color="#FFFFFF"
          startIcon={<GoogleIcon sx={{ alignSelf: 'flex-start' }} />}
        >
          Log in with Google
        </SocialButtonGmail>
      </Box> */}
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby='customized-dialog-title'
        open={open}
      >
        <ForgotPassDialog
          id='customized-dialog-title'
          onClose={handleClose}
        ></ForgotPassDialog>
        <DialogContent
          style={{
            alignItems: 'center',
            display: 'flex',
            flexDirection: 'column'
          }}
        >
          <div
            style={{
              textAlign: 'center',
              marginBottom: 20,
              fontSize: 24,
              fontWeight: 500,
              color: '#000'
            }}
          >
            Forgot your password?
          </div>
          <div
            style={{
              textAlign: 'center',
              marginBottom: 20,
              width: '70%',
              fontSize: 13,
              color: '#757575'
            }}
          >
            Just enter your email address below and we will send you a link to
            reset your password
          </div>
          <Box
            component='form'
            noValidate
            sx={{
              display: 'grid',
              width: '80%',
              gridTemplateColumns: { sm: '1fr' },
              gap: 2
            }}
          >
            <UITextField
              id='email'
              // label='Just enter your email address below and we will send you a link to reset your password'
              placeholder='Enter your email address'
              icon={<MessageIcon />}
              onChange={onChange('email1')}
              error={errors?.email1}
            />
            {emailSent && (
              <div
                style={{
                  marginBottom: 20,
                  width: '100%',
                  fontSize: 13,
                  color: '#EF771F'
                }}
              >
                A link has been sent to the specified e-mail
              </div>
            )}
          </Box>
          <UIButton
            color='primary'
            sx={{ width: '334px', my: 4 }}
            onClick={() => handleReset()}
          >
            Reset Password
          </UIButton>
        </DialogContent>
      </BootstrapDialog>
    </Box>
  )
}

export default PageLogin
