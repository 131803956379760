import * as React from 'react';
import { useNavigate } from 'react-router-dom';

import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

import { ReactComponent as CheckmarkIcon } from '@assets/icons/checkmark.svg';
import UIButton from '@components/UIButton';
import { defaltAppPage } from '@screen/PageIndex';

function PageFinishedCompany() {
  const navigate = useNavigate();
  return (
    <Box
      sx={{
        flex: 1,
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
        justifyContent: 'center',
        mt: 3,
      }}
    >
      <CheckmarkIcon />
      <Typography
        sx={{
          fontWeight: '500',
          fontSize: '1.75rem',
          textAlign: 'center',
          my: 3,
        }}
      >
        Great!
        <br />
        Your account is ready!
      </Typography>
      <UIButton
        color="primary"
        sx={{ width: '356px', my: 1 }}
        onClick={() => navigate(defaltAppPage['Company'])}
      >
        Start Hiring!
      </UIButton>
    </Box>
  );
}

export default PageFinishedCompany;
