import * as React from 'react'
import AppBar from '@mui/material/AppBar'
import Box from '@mui/material/Box'
import Toolbar from '@mui/material/Toolbar'
import Typography from '@mui/material/Typography'
import Container from '@mui/material/Container'
import Avatar from '@mui/material/Avatar'
import Tooltip from '@mui/material/Tooltip'
import { drawerWidth } from './WrapperDashboard'
import { logoutRequest, selectUser } from '@features/auth/authSlice'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router'
import { Button } from '@mui/material'

import { ReactComponent as ArrowDownIcon } from '@assets/icons/arrowDown.svg'
import { ReactComponent as SettingIcon } from '@assets/icons/setting.svg'
import { ReactComponent as FaqIcon } from '@assets/icons/faq.svg'
import { ReactComponent as LogoutIcon } from '@assets/icons/logout.svg'
import { ReactComponent as ProfileIcon } from '@assets/icons/profile.svg'
import { ReactComponent as ArrowRightIcon } from '@assets/icons/arrowRight.svg'

import UIMenu from './UIMenu'
import { useMenu } from '@utils/common'

const AppBarDash = () => {
  const user = useSelector(selectUser)
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const [el, handleOpenMenu] = useMenu()

  const settings = [
    {
      label: 'View Profile',
      action: () => {
        navigate('/app/profile')
      },
      leftIcon: <ProfileIcon />,
      rightIcon: <ArrowRightIcon />
    },
    {
      label: 'Account Settings',
      action: () => {
        navigate('/app/settings')
      },
      leftIcon: <SettingIcon />,
      rightIcon: <ArrowRightIcon />
    },
    {
      label: 'FAQ',
      action: () => {
       window.open('https://www.trylander.com/frequently-asked-questions')
      },
      leftIcon: <FaqIcon />,
      rightIcon: <ArrowRightIcon />
    },
    {
      label: 'Log Out',
      action: () =>
        dispatch(logoutRequest()).then(() => {
          navigate('/')
        }),
      leftIcon: <LogoutIcon />,
      rightIcon: <ArrowRightIcon />
    }
  ]

  const name = `${user?.first_name} ${user?.last_name}`.trim()
  const photo = user?.[(user?.type || 'candidate').toLowerCase()]?.photo

  return (
    <AppBar
      // position="static"
      position='fixed'
      sx={{
        width: `calc(100% - ${drawerWidth}px)`,
        ml: `${drawerWidth}px`,
        backgroundColor: 'white'
      }}
      elevation={0}
    >
      <Container maxWidth='xl'>
        <Toolbar disableGutters sx={{ height: '72px' }}>
          <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}></Box>

          <Box sx={{ flexGrow: 0 }}>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center'
              }}
            >
              <Tooltip title='Open menu'>
                <Button onClick={handleOpenMenu} sx={{ p: 0 }}>
                  <Avatar alt={name} src={photo} />
                  <Typography
                    sx={{
                      //
                      color: '#000',
                      fontWeight: '500',
                      fontSize: '1rem',
                      textAlign: 'center',
                      mx: 1,
                      textTransform: 'none'
                    }}
                  >
                    {name}
                  </Typography>
                  <ArrowDownIcon />
                </Button>
              </Tooltip>
            </Box>
            <UIMenu el={el} listItem={settings} />
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  )
}
export default AppBarDash
