import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

const initialState = {
  snack: {},
};

export const utilSlice = createSlice({
  name: 'util',
  initialState,
  reducers: {
    updateSnack: (state, action) => {
      state.snack = action.payload;
    },
  },
  // extraReducers: (builder) => {
  //   builder
  //     .addCase(incrementAsync.pending, (state) => {
  //       state.status = 'loading';
  //     })
  //     .addCase(incrementAsync.fulfilled, (state, action) => {
  //       state.status = 'idle';
  //       state.value += action.payload;
  //     });
  // },
});

export const { updateSnack } = utilSlice.actions;

export const selectSnack = (state) => state.util.snack;

export default utilSlice.reducer;
