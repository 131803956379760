import * as React from 'react';
import { Outlet } from 'react-router';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';

import AppBarMain from './AppBarMain';

const Offset = styled('div')(({ theme }) => theme.mixins.toolbar);

const WrapperMain = () => {
  return (
    <div>
      <AppBarMain />
      <Offset />
      <Box
        sx={{
          backgroundColor: 'background.paper',
          mx: '50px',
          mb: '50px',
          minHeight: '82vh',
          borderEndEndRadius: '30px',
          borderEndStartRadius: '30px',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          // justifyContent: 'center',
        }}
      >
        <Outlet />
      </Box>
    </div>
  );
};
export default WrapperMain;
