import React from 'react'
import { Typography, IconButton, Dialog, Box } from '@mui/material'
import { ReactComponent as CloseIcon } from '@assets/icons/close.svg'

export default function UIDialog ({ open, handleClose, headingText, text }) {
  return (
    <Dialog onClose={handleClose} open={open} sx={{ borderRadius: '10px' }}>
      <Box sx={{ position: 'relative', m: 5 }}>
        <Typography
          sx={{
            fontWeight: '500',
            fontSize: 24, // 21
            textAlign: 'center',
            mx: 5,
            mb: 2
          }}
        >
          {headingText}
        </Typography>
        <Typography
          sx={{
            fontWeight: '500',
            fontSize: '1.1.3125rem', // 21
            textAlign: 'center',
            mx: 5
          }}
        >
          {text}
        </Typography>
      </Box>
      <Box sx={{ position: 'absolute', top: 0, right: 0 }}>
        <IconButton
          onClick={handleClose}
          aria-label='save'
          sx={{
            m: 2,
            width: '46px',
            height: '46px',
            // boxShadow: '0px 4px 25px rgba(0, 0, 0, 0.15)',
            backgroundColor: 'rgba(196, 196, 196, 0.3)'
          }}
        >
          <CloseIcon />
        </IconButton>
      </Box>
    </Dialog>
  )
}
