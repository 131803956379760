import * as React from 'react'
import UIButton, { UploadButton } from '@components/UIButton'
import { useNavigate, useParams, NavLink } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { unwrapResult } from '@reduxjs/toolkit'

import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import Engineeringguide1 from '../../assets/images/Engineeringguide1.jpg'
import Engineeringguide2 from '../../assets/images/Engineeringguide2.jpg'
import Legalguide from '../../assets/images/Legalguide.jpg'
import Scienceguide1 from '../../assets/images/Scienceguide1.jpg'
import Scienceguide2 from '../../assets/images/Scienceguide2.jpg'
import { ReactComponent as LocationIcon } from '@assets/icons/location.svg'

import { ReactComponent as VideoIcon } from '@assets/icons/video.svg'
import { ReactComponent as CloudIcon } from '@assets/icons/could.svg'
import { Button, Popover } from '@mui/material'

import {
  updateProfileJsonRequest,
  updateProfileRequest
} from '@features/auth/authSlice'
import VideoRecorder from './video'
import { selectCategories, jobCategoryRequest } from '@features/jobs/jobSlice'
import { JobCategoryButton, TextSubTitle } from '@screen/jobs/components'
import { updateSnack } from '@features/util/utilSlice'
import { STATE_TYPES } from '@utils/phoneMask'
import UITextField from '@components/UITextField'
import { MenuItem, IconButton } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import Popup from '@components/Popup'

// import { ReactComponent as SetupProfileSvg } from '@assets/icons/imgProfile.svg';

const RecordButton = ({ onPress, label }) => {
  return (
    <UIButton
      color='secondary'
      variant='outlined'
      sx={{
        width: '235px',
        py: '16px',
        fontSize: '1.375rem' //22px
      }}
      onClick={onPress}
      startIcon={<VideoIcon />}
    >
      {label}
    </UIButton>
  )
}

const PageUploadVideo = ({ onNext }) => {
  const dispatch = useDispatch()
  const categories = useSelector(selectCategories)

  const [openRecord, setOpenRecord] = React.useState(false)
  const [record, setRecord] = React.useState()

  //categories (interests)
  const [interests, setInterests] = React.useState({})
  const [roles, setRoles] = React.useState({})
  const [subInterests, setSubInterests] = React.useState([])
  const [preferedLocations, setPreferedLocations] = React.useState([])
  const [inputValue, setInputValue] = React.useState('')

  React.useEffect(() => {
    dispatch(jobCategoryRequest())
  }, [dispatch])
  // interests
  // preferred_locations
  // const [record, setRecord] = React.useState();
  const [currentFile, setCurrentFile] = React.useState()

  const params = useParams()
  const navigate = useNavigate()

  const handleSelectCategorie = (cat, sub) => {
    if (interests[cat]) {
      const newInt = { ...interests }
      const updateList = []
      for (let i = 0; i < subInterests.length; i++) {
        const element = subInterests[i]
        const isHave = sub?.some(e => e?.name === element?.name)
        if (!isHave) {
          updateList.push(element)
        }
      }
      delete newInt[cat]
      setInterests(newInt)
      setSubInterests(updateList)
    } else {
      const newInt = { ...interests }
      const newSub = subInterests.concat(sub)
      newInt[cat] = true
      setInterests(newInt)
      setSubInterests(newSub)
    }
  }
  const handleSelectSubCategorie = cat => {
    if (roles[cat]) {
      const newInt = { ...roles }
      delete newInt[cat]
      setRoles(newInt)
    } else {
      const newInt = { ...roles }
      newInt[cat] = true
      setRoles(newInt)
    }
  }
  const handleRecordingComplete = videoBlob => {
    setRecord(videoBlob)
  }

  const handleRecordOpen = () => {
    setOpenRecord(true)
  }
  const handleSubmit = () => {
    const body = {
      candidate: {
        interests: Object.keys(interests),
        roles: Object.keys(roles),
        preferred_locations: preferedLocations
      }
    }

    dispatch(updateProfileJsonRequest(body))

    // try sending array with formData array
    // Object.keys(interests).forEach((item) => {
    //   formData.append(`candidate.interests[]`, item);
    // });
    // preferedLocations.forEach((item) => {
    //   formData.append(`candidate.preferred_locations[]`, item);
    // });

    if (currentFile) {
      const formData = new FormData()

      formData.append('candidate.resume_file', currentFile)
      // candidate.video //
      if (record) {
        var videoFile = new File([record], 'record.flv', {
          type: record?.type,
          lastModified: new Date().getTime()
        })
        console.log('[dev] PageUploadVideo::handleSubmit::videoFile', videoFile)
        formData.append('candidate.video', videoFile)
      }
      dispatch(updateProfileRequest(formData))
        .then(unwrapResult)
        .then(data => {
          console.log('[dev]PageUploadVideo::data', data)
          // onNext();
        })
        .catch(data => {
          console.log('[dev]PageUploadVideo::catch::', data)
        })
      onNext()
    } else {
      dispatch(
        updateSnack({
          open: true,
          severity: 'error',
          message: 'Please upload a resume'
        })
      )
    }
    // // candidate.interests //
    // const interestsList = Object.keys(interests);
    // if (interestsList && interestsList?.length > 0) {
    //   formData.append(`candidate.interests[]`, interestsList);
    // }
    // // candidate.preferred_locations //
    // if (preferedLocations && preferedLocations?.length > 0) {
    //   formData.append(`candidate.preferred_locations[]`, preferedLocations);
    // }
  }

  const selectFile = event => {
    const file = event?.target?.files?.[0]
    console.log('[dev] PageUploadImage::selectFile::', file)
    if (file) {
      setCurrentFile(file)
    }
  }

  const [anchorEl, setAnchorEl] = React.useState(null)
  const [imageShow, setImageShow] = React.useState(null)
  const [imageShow1, setImageShow1] = React.useState(null)
  const [selectedName, setSelectedName] = React.useState('')

  const handleClick = (event, name) => {
    const imageshow =
      name === 'V - Engineering'
        ? Engineeringguide1
        : name === 'IX - Science'
        ? Scienceguide1
        : Legalguide
    const imageshow1 =
      name === 'V - Engineering' ? Engineeringguide2 : Scienceguide2
    setImageShow(imageshow)
    setSelectedName(name)
    setImageShow1(imageshow1)
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  function test (uri) {
    const url = uri
    const img = '<img src="' + url + '">'
    const popup = window.open()
    popup.document.write(img)
    popup.print()
  }

  const open = Boolean(anchorEl)
  const _id = open ? 'simple-popover' : undefined

  return (
    <Box
      sx={{
        py: 3,
        width: { sm: '543px' },
        display: 'flex',
        flexDirection: 'column'
      }}
    >
      <Typography
        sx={{
          //
          fontWeight: '500',
          fontSize: '28px',
          textAlign: 'center',
          my: 2
        }}
      >
        Record a video of your talent
      </Typography>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          flexDirection: 'column',
          alignItems: 'center'
          // gridColumn: 'span 2',
        }}
      >
        {(openRecord && (
          <VideoRecorder onRecordingComplete={handleRecordingComplete} />
        )) || <RecordButton onPress={handleRecordOpen} label='Record video' />}
      </Box>
      <Typography
        sx={{
          fontWeight: '500',
          fontSize: '	0.9375rem', // 15px
          color: '#00000050',
          px: 14,
          textAlign: 'center',
          mb: 1,
          mt: 1
        }}
      >
        Video length will be max. 30 seconds
      </Typography>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          flexDirection: 'column',
          alignItems: 'center'
          // gridColumn: 'span 2',UploadButton
        }}
      >
        <label htmlFor='btn-upload'>
          <input
            id='btn-upload'
            name='btn-upload'
            style={{ display: 'none' }}
            type='file'
            accept='application/msword, application/pdf'
            onChange={selectFile}
          />
          <UploadButton startIcon={<CloudIcon />}>Upload a resume</UploadButton>
        </label>
      </Box>
      <TextSubTitle>I’m interested in</TextSubTitle>
      <Box sx={{ mb: 3 }}>
        {categories?.map(({ name, subcategories }) => (
          <>
            <JobCategoryButton
              key={name}
              color='primary'
              active={interests[name]}
              onClick={() => {
                handleSelectCategorie(name, subcategories)
              }}
            >
              {name}
            </JobCategoryButton>
            {(name === 'V - Engineering' ||
              name === 'VIII - Legal' ||
              name === 'IX - Science') && (
              <Button
                aria-describedby={_id}
                className='gPopup'
                onClick={e => handleClick(e, name)}
              >
                G
              </Button>
            )}
          </>
        ))}
        <Popover
          id={_id}
          open={open}
          anchorEl={anchorEl}
          style={{ width: '90%', top: 50 }}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left'
          }}
        >
          <div className='rowBetween'>
            <Typography sx={{ p: 2 }}>
              {selectedName === 'V - Engineering'
                ? 'Engineering'
                : selectedName === 'VIII - Legal'
                ? 'Legal'
                : 'Science'}{' '}
              guide
            </Typography>
            <IconButton onClick={handleClose}>
              <CloseIcon />
            </IconButton>
          </div>
          <div className='rowBetween'>
            <Popup label={selectedName} />
          </div>
        </Popover>
      </Box>
      <TextSubTitle>Sub Categories</TextSubTitle>
      <Box sx={{ mb: 3 }}>
        {subInterests?.map(({ name }) => (
          <JobCategoryButton
            key={name}
            color='primary'
            active={roles[name]}
            onClick={() => {
              handleSelectSubCategorie(name)
            }}
          >
            {name}
          </JobCategoryButton>
        ))}
      </Box>
      {/* <Autocomplete
        multiple
        freeSolo
        id='prefered-locations'
        options={[]}
        value={preferedLocations}
        inputValue={inputValue}
        onChange={(event, newValue) => {
          setPreferedLocations(newValue)
        }}
        onInputChange={(event, newInputValue) => {
          const options = newInputValue.split(',')

          if (options.length > 1) {
            setPreferedLocations(
              preferedLocations
                .concat(options)
                .map(x => x.trim())
                .filter(x => x)
            )
            setInputValue('')
          } else {
            setInputValue(newInputValue)
          }
        }}
        renderInput={params => (
          <TextField
            {...params}
            label='Prefered Locations'
            placeholder='Use , as a delimiter'
          />
        )}
      /> */}
      <UITextField
        id='state'
        label='State'
        placeholder='Enter your state'
        multiple
        icon={<LocationIcon />}
        sx={{ gridColumn: 'span 2' }}
        onChange={value =>
          setPreferedLocations(
            typeof value === 'string' ? value.split(',') : value
          )
        }
        value={preferedLocations}
        // error={errors?.state}
        select
      >
        <MenuItem value=''>
          <em>Select your state</em>
        </MenuItem>
        {STATE_TYPES.map((state, index) => (
          <MenuItem key={index} value={state}>
            {state}
          </MenuItem>
        ))}
      </UITextField>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          flexDirection: 'column',
          alignItems: 'center'
          // gridColumn: 'span 2',
        }}
      >
        {
          // (record || currentFile) && (
          <UIButton
            color='primary'
            sx={{ width: '334px', my: 4 }}
            onClick={handleSubmit}
          >
            Continue
          </UIButton>
        }
      </Box>
    </Box>
  )
}
export default PageUploadVideo
