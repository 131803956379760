import { apiFetch } from '@utils/apiFetch'

const authAPI = apiFetch

export function apiaddEmployee (payload) {
  return authAPI.post(`/api/v1/employees/`, payload)
}

export function apigetEmployee () {
  return authAPI.get(`/api/v1/employees/internal/`)
}

export function apigetEmployeeByID (id) {
  return authAPI.get(`/api/v1/employees/${id}/`)
}

export function apiupdateEmployee (id, payload) {
  return authAPI.patch(`/api/v1/employees/${id}/`, payload)
}

export function apideleteEmployee (id) {
  return authAPI.delete(`/api/v1/employees/${id}/`, {})
}
