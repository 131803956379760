import * as React from 'react';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import { Box } from '@mui/material';

export default function UIRadio({
  label,
  options = [],
  onChange,
  start = false,
  ...params
}) {
  function handleChange(event) {
    console.log(
      `[dev] UIRadio::handleChange::value::${event?.target?.value}::`
    );
    onChange(event?.target?.value);
  }
  return (
    <FormControl
      component="fieldset"
      sx={{
        //
        justifyContent: 'center',
        alignItems: start ? 'flex-start' : 'center',
      }}
    >
      <FormLabel component="legend" sx={{ fontSize: '0.75rem' }}>
        {label}
      </FormLabel>
      <RadioGroup
        row
        aria-label="gender"
        name="row-radio-buttons-group"
        onChange={handleChange}
        sx={{ justifyContent: 'center' }}
        {...params}
      >
        {options.map(({ label, value }) => (
          <FormControlLabel
            key={`${label}-${value}`}
            value={value}
            control={<Radio />}
            label={
              <Box
                sx={{
                  height: '59px',
                  width: '65px',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  bgcolor: '#F4F4F4',
                  borderRadius: '10px',
                }}
              >
                {label}
              </Box>
            }
            sx={{
              mt: 1,
              '& .MuiTypography-root': {
                display: 'flex',
                flexAlign: 'center',
              },
            }}
          />
        ))}
      </RadioGroup>
    </FormControl>
  );
}
