import * as React from 'react'
import UIButton from '@components/UIButton'
import {
  Avatar,
  AccordionSummary,
  AccordionDetails,
  Accordion,
  Grid,
  Paper,
  Typography
} from '@mui/material'
import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import WrapperContent from '@components/WrapperContent'
import AddIcon from '@mui/icons-material/Add'
import HorizontalRuleIcon from '@mui/icons-material/HorizontalRule'

const Faq = () => {
  const navigate = useNavigate()

  const faqs = [
    {
      question:
        'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor?',
      answer:
        '"Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. ',
      id: '1'
    },
    {
      question:
        'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor?',
      answer:
        '"Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. ',
      id: '2'
    },
    {
      question:
        'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor?',
      answer:
        '"Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. ',
      id: '3'
    },
    {
      question:
        'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor?',
      answer:
        '"Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. ',
      id: '4'
    },
    {
      question:
        'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor?',
      answer:
        '"Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. ',
      id: '5'
    },
    {
      question:
        'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor?',
      answer:
        '"Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. ',
      id: '6'
    }
  ]

  const [expanded, setExpanded] = React.useState(false)

  const handleChange = panel => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false)
  }
  return (
    <WrapperContent
      label='Frequently asked questions'
      sx={{
        dispaly: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
      }}
    >
      <Paper elevation={0} sx={{ width: '100%', mb: 5, p: 5, borderRadius: 5 }}>
        <Grid container direction={'column'}>
          {faqs.map((item, index) => (
            <Accordion
              key={index}
              classes={{ root: 'accordionRoot' }}
              expanded={expanded === item.id}
              className={'faqAccordion'}
              onChange={handleChange(item.id)}
            >
              <AccordionSummary
                expandIcon={
                  expanded === item.id ? (
                    <HorizontalRuleIcon
                      sx={{
                        color: 'rgba(255, 145, 65, 1)'
                      }}
                    />
                  ) : (
                    <AddIcon />
                  )
                }
                aria-controls='panel1bh-content'
                id='panel1bh-header'
              >
                <Typography
                  sx={{
                    width: '90%',
                    color:
                      expanded === item.id ? 'rgba(255, 145, 65, 1)' : '#000',
                    flexShrink: 0
                  }}
                >
                  {item.question}
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>{item.answer}</Typography>
              </AccordionDetails>
            </Accordion>
          ))}
          <Typography sx={{ mt: 2, fontWeight: 'bold', fontSize: 18, mb: 1 }}>
            Still have questions?
          </Typography>
          <Typography>
            If you can not find answer to your question do not hesitate to
            <span className='text-secondary ml-1'>contact us.</span>
          </Typography>
        </Grid>
      </Paper>
    </WrapperContent>
  )
}
export default Faq
