import * as React from 'react'
import { useNavigate } from 'react-router-dom'

import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'

import { ReactComponent as CheckedIcon } from '@assets/icons/checked.svg'
import UIButton from '@components/UIButton'
import UICard from '@components/UICard'
import { ButtonJobContinue } from './PageJobCreate'
import { useDispatch, useSelector } from 'react-redux'
import {
  selectCategories,
  selectNewJob,
  updateJob
} from '@features/jobs/jobSlice'
import UITextField from '@components/UITextField'
import { TextSubTitle } from './components'
import { updateSnack } from '@features/util/utilSlice'

const JobSectionName = ({ onSubmit, jobDetail }) => {
  const { name } = useSelector(selectNewJob)
  const dispatch = useDispatch()
  React.useEffect(() => {
    if (jobDetail) {
      handleChange('name')(jobDetail?.name)
    }
  }, [jobDetail])
  const handleChange = key => value => {
    dispatch(updateJob({ key, value }))
    dispatch(
      updateSnack({
        open: false
      })
    )
  }
  const handleSubmit = () => {
    if (!name) {
      dispatch(
        updateSnack({
          open: true,
          severity: 'error',
          message: 'Please enter the job name'
        })
      )
    } else {
      onSubmit()
    }
  }
  return (
    <>
      <Box>
        <TextSubTitle>Job Name</TextSubTitle>
        <UITextField
          id='name'
          value={name}
          placeholder='Enter job name'
          // icon={<MessageIcon />}
          onChange={handleChange('name')}
          // error={errors?.email}
        />
      </Box>
      <ButtonJobContinue onSubmit={handleSubmit} />
    </>
  )
}

export default JobSectionName
