import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import 'whatwg-fetch';

import './index.css';
import App from './App';
// import { store } from './app/store';
import { initStore } from './app/store';
import { Provider } from 'react-redux';
import * as serviceWorker from './serviceWorker';

window.Buffer = window.Buffer || require('buffer').Buffer;

(async function () {
  const store = await initStore();
  return store;
})().then((store) => {
  ReactDOM.render(
    <React.StrictMode>
      <Provider store={store}>
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </Provider>
    </React.StrictMode>,
    document.getElementById('root')
  );
});

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
