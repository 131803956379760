import { Grid } from '@mui/material'
import React from 'react'

export default function Popup ({ label }) {
  return (
    <>
      {label === 'V - Engineering' && (
        <>
          <Grid container spacing={2}>
            <Grid item md={3} xs={6}>
              <p style={{ fontSize: 12, color: 'red', marginLeft: 10 }}>
                • Applied Engineering <br />
                Applied engineering deals with the application of management
                along with design and technical skills required for designing
                new products. Integrating systems, execution of new product
                designs, improving the current manufacturing processes while
                managing and directing the physical or technical functions of a
                firm are also encompassed in this field.
              </p>
              <p className='popuptext'>• Applied Engineering Sub Disciplines</p>
              <p className='popuptext'>• Manufacturing execution systems</p>
              <p className='popuptext'>• Supply chain management systems</p>
              <p className='popuptext'>• Six sigma</p>
              <p className='popuptext'>• Lean enterprises</p>
              <p className='popuptext'>• Quality control</p>
              <p className='popuptext'>
                • Motorsports management and technology
              </p>
              <p className='popuptext1'>• Nano engineering technology</p>
            </Grid>
            <Grid item md={3} xs={6}>
              <p style={{ fontSize: 12, color: 'red', marginLeft: 10 }}>
                • Chemical Engineering
                <br />
                This type of engineering uses the principles of chemistry,
                biology, physics, and math to design and manufacture products
                through chemical processes.
              </p>
              <p className='popuptext'>• Biochemical engineering</p>
              <p className='popuptext'>
                • Biochemical engineering engineering/bioengineering
              </p>
              <p className='popuptext'>• Biomolecular engineering</p>
              <p className='popuptext'>• Genetic engineering</p>
              <p className='popuptext'>• Materials science and engineering</p>
              <p className='popuptext'>• Metallurgical engineering</p>
              <p className='popuptext'>• Nanoengineering</p>
              <p className='popuptext'>• Paper science and engineering</p>
              <p className='popuptext'>• Plastics engineering</p>
              <p className='popuptext1'>• Textile engineering</p>
            </Grid>
            <Grid item md={3} xs={6}>
              <p style={{ fontSize: 12, color: 'red', marginLeft: 10 }}>
                • Civil Engineering
                <br />
                Civil engineering is a professional discipline that entails the
                design, construction, and maintenance of the natural world and
                man-made structures, such as bridges, dams, and sewage systems.
              </p>
              <p className='popuptext'>• Architectural engineering</p>
              <p className='popuptext'>• Construction management</p>
              <p className='popuptext'>• Ecological engineering</p>
              <p className='popuptext'>• Environmental engineering</p>
              <p className='popuptext'>• Geotechnical engineering</p>
              <p className='popuptext'>• Mining engineering</p>
              <p className='popuptext'>• Structural engineering</p>
              <p className='popuptext'>Transport/transportation engineering</p>
              <p className='popuptext1'>• Water (resources) engineering</p>
            </Grid>
            <Grid item md={3} xs={6}>
              <p style={{ fontSize: 12, color: 'red', marginLeft: 10 }}>
                • Electrical Engineering
                <br />
                As its name suggests, this type of engineering focuses on
                technology, specifically the design and production of electronic
                devices.
              </p>
              <p className='popuptext'>Computer engineering</p>
              <p className='popuptext'>• Electrical power engineering</p>
              <p className='popuptext'>• Electronic(s) engineering</p>
              <p className='popuptext'>• Network engineering</p>
              <p className='popuptext'>
                Optical engineering/optical sciences and engineering
              </p>
              <p className='popuptext'>• Power systems engineering</p>
              <p className='popuptext'>• Software engineering</p>
              <p className='popuptext1'>• Telecommunications engineering</p>
            </Grid>
            <Grid item md={3} xs={6}>
              <p style={{ fontSize: 12, color: 'red', marginLeft: 10 }}>
                • Industrial Engineering
                <br />
                Industrial engineering is all about efficiency and optimization
                in areas such as business, finance, production, and management.
              </p>
              <p className='popuptext'>• Financial engineering</p>
              <p className='popuptext'>
                • Fire (protection) and safety engineering
              </p>
              <p className='popuptext'>• Management science/engineering</p>
              <p className='popuptext'>
                • Manufacturing/production engineering
              </p>
              <p className='popuptext'>• Operations research</p>
              <p className='popuptext'>• Safety engineering</p>
              <p className='popuptext'>• Supply chain management/engineering</p>
              <p className='popuptext1'>• Systems engineering/science</p>
            </Grid>
            <Grid item md={3} xs={6}>
              <p style={{ fontSize: 12, color: 'red', marginLeft: 10 }}>
                • Mechanical Engineering
                <br />
                This original branch of engineering involves designing,
                manufacturing, operating, and testing machines and other
                devices.
              </p>
              <p className='popuptext'>• Acoustical/sound engineering</p>
              <p className='popuptext'>• Aeronautical engineering</p>
              <p className='popuptext'>• Aerospace engineering</p>
              <p className='popuptext'>• Astronautical engineering</p>
              <p className='popuptext'>• Automotive engineering</p>
              <p className='popuptext'>• Energy engineering</p>
              <p className='popuptext'>
                • Marine engineering/naval architecture
              </p>
              <p className='popuptext'>• Ocean engineering</p>
              <p className='popuptext'>• Renewable energy engineering</p>
              <p className='popuptext'>• Robotics engineering</p>
              <p className='popuptext1'>• Thermal engineering/science</p>
            </Grid>
          </Grid>
        </>
      )}
      {label === 'VIII - Legal' && (
        <Grid container>
          <Grid item md={12} xs={12} sx={{ mr: 2 }}>
            <p style={{ fontSize: 12, color: 'red', marginLeft: 10 }}>
              • Attorney
            </p>
            <p className='popuptext'>• Admiralty Law</p>
            <p className='popuptext'>• Bankruptcy Law</p>
            <p className='popuptext'>• Corporate Law</p>
            <p className='popuptext'>• Civil Rights Law</p>
            <p className='popuptext'>• Criminal Law</p>
            <p className='popuptext'>• Entertainment Law</p>
            <p className='popuptext'>• Environmental Law</p>
            <p className='popuptext'>• Family Law</p>
            <p className='popuptext'>• Health Law</p>
            <p className='popuptext'>• Immigration Law</p>
            <p className='popuptext'>• Intellectual Property Law</p>
            <p className='popuptext'>• International Law</p>
            <p className='popuptext'>• Labor Law</p>
            <p className='popuptext'>• Military Law</p>
            <p className='popuptext'>• Personal Injury Law</p>
            <p className='popuptext'>• Real Estate Law</p>
            <p className='popuptext1'>• Tax Law</p>
          </Grid>
        </Grid>
      )}
      {label === 'IX - Science' && (
        <Grid container>
          <Grid item md={2} xs={6}>
            <p style={{ fontSize: 12, color: 'red', marginLeft: 10 }}>
              • Formal Science
            </p>
            <p className='popuptext'>• Decision Theory</p>
            <p className='popuptext'>• Logic</p>
            <p className='popuptext'>• Mathematics</p>
            <p className='popuptext'>• Statistics</p>
            <p className='popuptext'>• Systems Theory</p>
            <p className='popuptext1'>• Theoretical Computer Science</p>
          </Grid>
          <Grid item md={2} xs={6}>
            <p style={{ fontSize: 12, color: 'red', marginLeft: 10 }}>
              • Life Science
            </p>
            <p className='popuptext'>• Biology</p>
            <p className='popuptext'>• Botany</p>
            <p className='popuptext'>• Ecology</p>
            <p className='popuptext'>• Medicine</p>
            <p className='popuptext'>• Microbiology</p>
            <p className='popuptext1'>• Zoology</p>
          </Grid>
          <Grid item md={2} xs={6}>
            <p style={{ fontSize: 12, color: 'red', marginLeft: 10 }}>
              • Physical Science
            </p>
            <p className='popuptext'>• Astronomy</p>
            <p className='popuptext'>• Geology</p>
            <p className='popuptext'>• Geography</p>
            <p className='popuptext'>• Oceanography</p>
            <p className='popuptext'>• Physics</p>
            <p className='popuptext'>• Chemistry</p>
            <p className='popuptext1'>• Earth Science</p>
          </Grid>
          <Grid item md={2} xs={6}>
            <p style={{ fontSize: 12, color: 'red', marginLeft: 10 }}>
              • Social Science
            </p>
            <p className='popuptext'>• Sociology</p>
            <p className='popuptext'>• Anthropology</p>
            <p className='popuptext'>• Archaeology</p>
            <p className='popuptext'>• Economics</p>
            <p className='popuptext'>• Human Geography</p>
            <p className='popuptext'>• Linguistics</p>
            <p className='popuptext'>• Management Science</p>
            <p className='popuptext'>• Media Studies</p>
            <p className='popuptext'>• Political Science</p>
            <p className='popuptext'>• Psychology</p>
            <p className='popuptext1'>• Social History</p>
          </Grid>
          <Grid item md={2} xs={6}>
            <p style={{ fontSize: 12, color: 'red', marginLeft: 10 }}>
              • Data Science
            </p>
            <p className='popuptext1'>
              • A Data Scientist is a person who should be able to leverage
              existing data sources, and create new ones as needed in order to
              extract meaningful information and actionable insights. A data
              scientist does this through business domain expertise, effective
              communication and results interpretation, and utilization of any
              and all relevant statistical techniques, programming languages,
              software packages and libraries, and data infrastructure.
            </p>
          </Grid>
        </Grid>
      )}
    </>
  )
}
