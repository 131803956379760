import * as React from 'react'
import { Typography, Paper } from '@mui/material'
import { Link } from 'react-router-dom'

const AccountDeleted = () => {
  return (
    <Paper
      elevation={0}
      sx={{ width: '100%', mb: 5, mt: 5, p: 5, borderRadius: 5 }}
    >
      <Typography variant='h5' sx={{ textAlign: 'center' }}>
        Your account has been deleted. To access our services again, please
        create a new Lander profile. Return to <Link to={'/'}>Main Menu</Link>
      </Typography>
    </Paper>
  )
}

export default AccountDeleted
