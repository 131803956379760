import * as React from 'react'
import UIButton from '@components/UIButton'
import { Box, Button, Typography } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { selectToken, selectUser } from '@features/auth/authSlice'
import { defaltAppPage } from './PageIndex'

const PageHome = () => {
  const navigate = useNavigate()
  const token = useSelector(selectToken)
  const user = useSelector(selectUser)

  React.useEffect(() => {
    if (token) {
      // if (!user?.candidate?.photo) {
      //   navigate('/setup');
      // } else {
      //   navigate('/app');
      // }
      navigate(defaltAppPage[user?.type || 'Candidate'])
    }
  }, [token, user, navigate])

  return (
    <Box
      sx={{
        flex: 1,
        display: 'flex',
        alignItems: 'center'
      }}
    >
      <Box
        sx={{
          // px: '60px',
          // py: '84px',
          px: 6,
          py: 3,
          my: 2,
          borderWidth: '2px',
          borderColor: '#8E8C8C13',
          borderRadius: '20px',
          borderStyle: 'solid',
          maxWidth: '632px',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center'
        }}
      >
        <Typography
          sx={{
            //
            fontWeight: '500',
            fontSize: '28px',
            textAlign: 'center'
          }}
        >
          Lander is an online service platform to hire candidates
        </Typography>
        <Typography
          sx={{
            color: '#33333350',
            fontSize: '15px',
            textAlign: 'center',
            my: 3
          }}
        >
          To form an organized arena where employers and candidates can connect
          on a level that’s deeper than a piece of paper
        </Typography>
        <UIButton
          sx={{ width: '356px', my: 1 }}
          onClick={() => navigate('/register/company')}
        >
          Join as a Hiring Manager
        </UIButton>
        <UIButton
          color='primary'
          sx={{ width: '356px', my: 1 }}
          onClick={() => navigate('/register/candidate')}
        >
          Join as a Candidate
        </UIButton>

        <Box
          sx={{
            display: 'flex',
            alignItems: 'center'
          }}
        >
          <Typography
            sx={{
              color: '#33333350',
              fontSize: '20px',
              textAlign: 'center',
              my: 3
            }}
          >
            Already have an account?
          </Typography>
          <Button
            // color="secondary"
            // variant="contained"
            sx={{
              // my: 1,
              // py: '21px',
              // color: 'white',
              display: 'block',
              textTransform: 'none',
              fontSize: '16px',
              fontSize: '20px'
              // width: '356px',
            }}
            onClick={() => navigate('/login')}
          >
            Login
          </Button>
        </Box>
      </Box>
    </Box>
  )
}
export default PageHome
