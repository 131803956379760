import * as React from 'react'
import UIButton, {
  SocialButtonFacebook,
  SocialButtonGmail
} from '@components/UIButton'
import { useNavigate, useParams, NavLink } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { unwrapResult } from '@reduxjs/toolkit'

import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import { styled } from '@mui/material/styles'
import Typography from '@mui/material/Typography'
import Button from '@mui/material/Button'
import Box from '@mui/material/Box'
import Input from '@mui/material/Input'
import InputLabel from '@mui/material/InputLabel'
import UILoading from '@components/UILoading'
import InputAdornment from '@mui/material/InputAdornment'
import OutlinedInput from '@mui/material/OutlinedInput'
import IconButton from '@mui/material/IconButton'
import FormControl from '@mui/material/FormControl'
import TextField from '@mui/material/TextField'
import AccountCircle from '@mui/icons-material/AccountCircle'
// import Divider from '@mui/material/Divider';
import DeleteIcon from '@mui/icons-material/Delete'
import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import DialogContent from '@mui/material/DialogContent'
import DialogActions from '@mui/material/DialogActions'
import CloseIcon from '@mui/icons-material/Close'
import UITextField, { UITextFieldMask } from '@components/UITextField'

import { ReactComponent as ProfileIcon } from '@assets/icons/profile.svg'
import { ReactComponent as MessageIcon } from '@assets/icons/message.svg'
import { ReactComponent as LockIcon } from '@assets/icons/lock.svg'
import { ReactComponent as CallIcon } from '@assets/icons/call.svg'

import { ReactComponent as GoogleIcon } from '@assets/icons/google.svg'
import { ReactComponent as FacebookIcon } from '@assets/icons/facebook.svg'
import {
  forgotPassRequest,
  resetPasswordRequest,
  loginRequest,
  socialAuthRequest
} from '@features/auth/authSlice'
import { defaltAppPage } from './PageIndex'
import { updateSnack } from '@features/util/utilSlice'
import { Config } from '@utils/common'
import { CircularProgress } from '@mui/material'

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
    width: '600px',
    [theme.breakpoints.down('md')]: {
      width: '100%'
    }
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1)
  }
}))
const ForgotPassDialog = props => {
  const { children, onClose, ...other } = props

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label='close'
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: theme => theme.palette.grey[500]
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  )
}
const mapType = {
  candidate: 'Candidate',
  company: 'Company'
}
const fieldValidationSchema = yup.object().shape({
  password_1: yup
    .string()
    .required('Please enter your password')
    .min(6),
  password_2: yup
    .string()
    .oneOf([yup.ref('password_1'), null], 'Passwords must match')
})

const defaultValues = {
  password_1: '',
  password_2: ''
}

const PageReset = () => {
  const {
    register,
    setValue,
    handleSubmit,
    getValues,
    clearErrors,
    setError,
    formState: { errors }
  } = useForm({
    defaultValues,
    resolver: yupResolver(fieldValidationSchema)
  })
  const dispatch = useDispatch()

  const navigate = useNavigate()
  const [open, setOpen] = React.useState(false)

  const handleClose = () => {
    navigate('/login')
  }

  const params = new Proxy(new URLSearchParams(window.location.search), {
    get: (searchParams, prop) => searchParams.get(prop)
  })
  // Get the value of "some_key" in eg "https://example.com/?some_key=some_value"
  let token = params.token // "some_value"

  React.useEffect(() => {
    setValue('password_1', defaultValues.password_1)
    setValue('password_2', defaultValues.password_2)
  }, [setValue])
  React.useEffect(() => {
    register('password_2')
    register('password_1')
  }, [register])

  const onChange = key => value => {
    clearErrors(key)
    setValue(key, value)
  }

  const handleReset = () => {
    const payload = {
      password_1: getValues('password_1'),
      password_2: getValues('password_2')
    }
    dispatch(resetPasswordRequest({ payload, token }))
      .then(unwrapResult)
      .then(data => {
        console.log('[dev]PageReset::forgotPassRequest', data)
        dispatch(
          updateSnack({
            open: true,
            severity: 'success',
            message: data?.detail
          })
        )
        navigate('/login')
      })
      .catch(data => {
        if (data?.detail) {
          dispatch(
            updateSnack({
              open: true,
              severity: 'error',
              message: data?.detail
            })
          )
        }
        console.log('[dev]PageReset::catch::', data)
      })
  }

  console.log('getValues()', getValues())
  if (token) {
    return (
      <Box
        sx={{
          py: 3,
          width: { sm: '543px', md: '700px' },
          display: 'flex',
          flexDirection: 'column'
        }}
      >
        <BootstrapDialog
          onClose={handleClose}
          aria-labelledby='customized-dialog-title'
          open={true}
        >
          <ForgotPassDialog
            id='customized-dialog-title'
            onClose={handleClose}
          ></ForgotPassDialog>
          <DialogContent
            style={{
              alignItems: 'center',
              display: 'flex',
              flexDirection: 'column'
            }}
          >
            <div
              style={{
                textAlign: 'center',
                marginBottom: 20,
                fontSize: 24,
                fontWeight: 500,
                color: '#000'
              }}
            >
              Enter new password
            </div>
            <div
              style={{
                textAlign: 'center',
                marginBottom: 20,
                width: '70%',
                fontSize: 13,
                color: '#757575'
              }}
            >
              Enter a new password & Recover your account
            </div>
            <Box
              component='form'
              noValidate
              sx={{
                display: 'grid',
                width: '100%',
                gridTemplateColumns: { sm: '1fr' },
                gap: 2
              }}
            >
              <UITextField
                id='email'
                label='New Password'
                placeholder='New Password'
                icon={<LockIcon />}
                type='password'
                onChange={onChange('password_1')}
                error={errors?.password_1}
              />
              <UITextField
                id='email'
                label='Confirm Password'
                placeholder='New Password'
                icon={<LockIcon />}
                type='password'
                onChange={onChange('password_2')}
                error={errors?.password_2}
              />
            </Box>
            <UIButton
              color='primary'
              sx={{ width: '234px', my: 4 }}
              onClick={() => handleReset()}
            >
              Submit
            </UIButton>
          </DialogContent>
        </BootstrapDialog>
      </Box>
    )
  }
  return <CircularProgress style={{ marginTop: '20%' }} />
}

export default PageReset
