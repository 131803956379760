import * as React from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import AppBar from '@mui/material/AppBar'
import Box from '@mui/material/Box'
import Toolbar from '@mui/material/Toolbar'
import Container from '@mui/material/Container'
import Button from '@mui/material/Button'

import { ReactComponent as LogoIcon } from '@assets/icons/logo.svg'
import { logoutRequest, selectToken } from '@features/auth/authSlice'

const mapConfig = {
  login: {
    // label: 'Not a member?',
    labelAction: () => {},
    button: 'Register',
    buttonAction: '/'
  },
  register: {
    label: 'Already a member?',
    labelAction: () => {},
    button: 'Login',
    buttonAction: '/login'
  }
}

const AppBarMain = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const location = useLocation()
  const token = useSelector(selectToken)

  const actionConfig = React.useMemo(() => {
    console.log('[dev] AppBarMain::location', location)

    if (token) {
      return {
        label: 'Set up Profile',
        labelAction: '/setup',
        button: 'Logout',
        buttonAction: () => {
          dispatch(logoutRequest()).then(() => {
            navigate('/')
          })
        }
      }
    }

    if (location?.pathname) {
      const path = location?.pathname?.split('/')[1]
      if (path && mapConfig[path]) return mapConfig[path]
    }

    return {
      // label: 'Login',
      // labelAction: '/login',
      // button: 'Register',
      // buttonAction: '/',
    }
  }, [location, dispatch, navigate, token])

  const handleAction = action => {
    if (typeof action === 'string') {
      navigate(action)
    } else if (typeof action === 'function') {
      action()
    }
  }

  const pathname = window.location.pathname
  return (
    <AppBar position='fixed' elevation={0}>
      <Container maxWidth='xl'>
        <Toolbar disableGutters sx={{ height: '72px' }}>
          <Button
            sx={{ mr: 2 /*display: { xs: 'none', md: 'flex' } */ }}
            onClick={() =>
              pathname === '/'
                ? window.open('http://trylander.com/')
                : navigate('/')
            }
          >
            <LogoIcon />
          </Button>
          <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'flex' } }}></Box>
          <Box sx={{ flexGrow: 0, display: 'flex', alignItems: 'center' }}>
            {actionConfig.label && (
              <>
                <Button
                  onClick={() => {
                    handleAction(actionConfig.labelAction)
                  }}
                  sx={{
                    my: 2,
                    color: 'white',
                    display: 'block',
                    textTransform: 'none',
                    fontSize: '16px'
                  }}
                >
                  {actionConfig.label}
                </Button>
                <Box
                  sx={{
                    background: '#FFFFFF30',
                    height: '34px',
                    width: '1px',
                    mr: 3,
                    ml: 2
                  }}
                ></Box>
              </>
            )}

            <Box>
              {actionConfig.button && (
                <Button
                  color='secondary'
                  variant='contained'
                  onClick={() => {
                    handleAction(actionConfig.buttonAction)
                  }}
                  sx={{
                    my: 2,
                    color: 'white',
                    display: 'block',
                    textTransform: 'none',
                    fontSize: '16px',
                    width: '136px'
                  }}
                >
                  {actionConfig.button}
                </Button>
              )}
            </Box>
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  )
}
export default AppBarMain

// {
//   label: 'Login',
//   labelAction: '/login',
//   button: 'Register',
//   buttonAction: '/register',
// }
