import * as React from 'react'
import UIButton from '@components/UIButton'
import {
  Avatar,
  Dialog,
  Button,
  Divider,
  Grid,
  Paper,
  Typography,
  CircularProgress
} from '@mui/material'
import { useNavigate } from 'react-router-dom'
import CloseIcon from '@mui/icons-material/Close'
import { useDispatch, useSelector } from 'react-redux'
import WrapperContent from '@components/WrapperContent'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import {
  getPlans,
  selectedPlans,
  _unsubscribePlan
} from '@features/price/priceSlice'
import { useState } from 'react'
import {
  currentProfileRequest,
  selectCurrentUser,
  selectUser,
  selectUserProfile,
  userProfileRequest
} from '@features/auth/authSlice'
import {
  getEmployee,
  selectedAllEmployees,
  _deleteEmployee
} from '@features/employee/employeeSlice'
import { updateSnack } from '@features/util/utilSlice'
import moment from 'moment'

const Price = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const user = useSelector(selectCurrentUser)
  const allEmployees = useSelector(selectedAllEmployees)
  const [open, setOpen] = React.useState(false)
  const [open1, setOpen1] = React.useState(false)
  const [userName, setUserName] = React.useState('')
  const [id, setID] = React.useState('')
  const authUser = useSelector(selectUser)

  const handleClose = () => {
    setOpen(false)
    setID('')
    setUserName('')
  }

  const handleClose1 = () => {
    setOpen1(false)
  }

  const [state, setState] = useState({
    plans: [],
    showPlan: false,
    loadingDelete: false,
    loadingUnsubscribe: false
  })
  const { plans, showPlan, loadingDelete, loadingUnsubscribe } = state

  React.useEffect(() => {
    if (user?.id) {
      handleChange(
        'showPlan',
        !user?.subscription || user?.subscribed === 'canceled' ? true : false
      )
      // dispatch(userProfileRequest(user?.id))
    }
  }, [dispatch, user])

  React.useEffect(() => {
    dispatch(currentProfileRequest(authUser?.id))
    dispatch(getEmployee())
  }, [])

  React.useEffect(async () => {
    const res = await dispatch(getPlans())
    handleChange('plans', res.payload)
  }, [dispatch])

  const handleChange = (key, value) => {
    setState(pre => ({ ...pre, [key]: value }))
  }

  const deleteEmployee = async () => {
    try {
      handleChange('loadingDelete', true)
      const res = await dispatch(_deleteEmployee(id))
      if (
        res?.payload?.status === 201 ||
        res?.payload?.status === 200 ||
        res?.payload?.status === 204
      ) {
        setID('')
        dispatch(getEmployee())
        setOpen(false)
        setUserName('')
        dispatch(
          updateSnack({
            open: true,
            severity: 'success',
            message: 'User Deleted'
          })
        )
        handleChange('loadingDelete', false)
      } else {
        handleChange('loadingDelete', false)
        alert('Something went wrong!')
      }
    } catch (error) {
      handleChange('loadingDelete', false)
      const errorText = Object.values(error?.response?.data)
      alert(`Error: ${errorText}`)
    }
  }

  const _handleUnSubscribePlan = async () => {
    try {
      handleChange('loadingUnsubscribe', true)
      const res = await dispatch(_unsubscribePlan())
      console.log('res', res)
      if (res?.payload?.status === 201 || res?.payload?.status === 200) {
        handleChange('loadingUnsubscribe', false)
        setOpen1(false)
        dispatch(currentProfileRequest(user?.id))
        dispatch(
          updateSnack({
            open: true,
            severity: 'success',
            message: 'You have successfully unsubscribed'
          })
        )
        // navigate('/app/price')
      } else {
        handleChange('loadingUnsubscribe', false)
        alert('Something went wrong!')
      }
    } catch (error) {
      handleChange('loadingUnsubscribe', false)
      const errorText = Object.values(error?.response?.data)
      alert(`Error: ${errorText}`)
    }
  }

  const packages = [
    {
      title: 'Pro',
      price: '$79/mo',
      duration: 'Only monthly pricing available',
      data: [
        '7 Days free trial for first time Lander account',
        '4 Job Posts at a time with users',
        '2 Users',
        'Unlimited candidate search, viewing, messaging, & interviews'
      ]
    },
    {
      title: 'Business',
      price: '$89/mo',
      duration: 'Only monthly pricing available',
      data: [
        '7 Days free trial for first time Lander account',
        '8 Job Posts at a time with users',
        '4 Users',
        'Unlimited candidate search, viewing, messaging, & interviews'
      ]
    },
    {
      title: 'Elite',
      yearly: false,
      discount: '50%',
      price: '$99/mo',
      duration: 'Only monthly pricing available',
      data: [
        '7 Days free trial for first time Lander account',
        '12 Job Posts at a time with users',
        '6 Users',
        'Unlimited candidate search, viewing, messaging, & interviews'
      ]
    },
    {
      title: 'Executive',
      yearly: true,
      discount: '40%',
      noPrice: true,
      price: '$499/mo',
      duration: 'Only monthly pricing available',
      data: [
        '7 Days free trial for first time Lander account',
        'Unlimited Job Posts with users',
        '8 Users',
        'Unlimited candidate search, viewing, messaging, & interviews'
      ]
    }
  ]

  console.log('plans', plans)
  const packeageName = user?.subscription?.plan?.product?.name
  const disabled =
    packeageName === 'Pro'
      ? allEmployees?.length === 2
      : packeageName === 'Business'
      ? allEmployees?.length === 4
      : packeageName === 'Executive'
      ? allEmployees?.length === 8
      : allEmployees?.length === 6
  return (
    <WrapperContent
      label={!showPlan ? 'My Pricing Plan' : 'Pricing'}
      sx={{
        dispaly: 'flex',
        justifyContent: 'center'
      }}
      actions={
        <>
          {user?.subscribed !== 'canceled' && user?.subscription && (
            <Button
              onClick={() => handleChange('showPlan', !showPlan)}
              style={{
                backgroundColor: '#000',
                color: '#fff',
                width: 150,
                height: 50,

                fontSize: 14,
                textTransform: 'capitalize'
              }}
            >
              {showPlan ? 'See My Plan' : 'See All Plans'}
            </Button>
          )}
        </>
      }
    >
      {!showPlan ? (
        <div>
          <Paper
            elevation={5}
            sx={{ width: '100%', mb: 5, mt: 2, p: 3, borderRadius: 2 }}
          >
            <Grid container>
              <Grid container justifyContent={'space-between'}>
                <Grid container item xs={6}>
                  <Typography variant='h6'>
                    {user?.subscription?.plan?.product?.name}
                  </Typography>
                  <Typography
                    variant='h6'
                    style={{ color: 'rgba(255, 145, 65, 1)', marginLeft: 20 }}
                  >
                    {user?.subscription?.status === 'trialing'
                      ? 'Free Trial'
                      : 'Subscription'}
                  </Typography>
                </Grid>
                <Grid>
                  <Typography
                    variant='body1'
                    style={{ textTransform: 'capitalize' }}
                  >
                    {user?.remaining_trial +
                      (Number(user?.remaining_trial) === 1
                        ? ' Day Remaining'
                        : ' Days Remaining')}
                  </Typography>
                </Grid>
              </Grid>
              <div
                style={{
                  width: '100%',
                  backgroundColor: '#eee',
                  marginTop: 10,
                  marginBottom: 10,
                  height: 1
                }}
              />
              {packages
                .filter(
                  e => e.title === user?.subscription?.plan?.product?.name
                )[0]
                ?.data.map((res, index1) => (
                  <Grid key={index1} container sx={{ p: 1, pl: 2 }}>
                    <CheckCircleIcon sx={{ color: '#000', mr: 2 }} />
                    <p className={'pointsMonthly'}>{res}</p>
                  </Grid>
                ))}
              <Grid container justifyContent={'flex-end'}>
                <Button
                  onClick={() => {
                    setOpen1(true)
                  }}
                  style={{
                    backgroundColor: '#FD403A',
                    color: '#fff',
                    width: 150,
                    height: 50,
                    fontSize: 14,
                    textTransform: 'capitalize',
                    marginRight: 20
                  }}
                >
                  {'Cancel'}
                </Button>
              </Grid>
            </Grid>
          </Paper>

          {allEmployees?.map((employee, index) => (
            <Paper
              elevation={5}
              sx={{ width: '100%', mb: 5, mt: 2, p: 3, borderRadius: 2 }}
            >
              <Grid container direction={'column'}>
                <Typography variant='h6'>User {index + 1}</Typography>
                <div
                  style={{
                    width: '100%',
                    backgroundColor: '#eee',
                    marginTop: 10,
                    marginBottom: 10,
                    height: 1
                  }}
                />
                <Typography
                  variant='body2'
                  sx={{ color: '#000', opacity: 0.4 }}
                >
                  Name
                </Typography>
                <Typography variant='body1' sx={{ color: '#000' }}>
                  {employee?.first_name}
                </Typography>
                <Typography
                  variant='body2'
                  sx={{ color: '#000', opacity: 0.4, mt: 2 }}
                >
                  Email Address
                </Typography>
                <Typography variant='body1' sx={{ color: '#000' }}>
                  {employee?.email}
                </Typography>
                <Grid container justifyContent={'space-between'}>
                  <Grid>
                    <Typography
                      variant='body2'
                      sx={{ color: '#000', opacity: 0.4, mt: 2 }}
                    >
                      Password
                    </Typography>
                    <Typography variant='body1' sx={{ color: '#000' }}>
                      ********
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Button
                      onClick={() => {
                        setOpen(true)
                        setID(employee?.id)
                        setUserName(employee?.first_name)
                      }}
                      style={{
                        backgroundColor: '#FD403A',
                        color: '#fff',
                        width: 150,
                        height: 50,
                        fontSize: 14,
                        textTransform: 'capitalize',
                        marginRight: 20
                      }}
                    >
                      {'Delete User'}
                    </Button>
                    <Button
                      onClick={() => navigate(`/app/add-user/${employee?.id}`)}
                      style={{
                        backgroundColor: 'rgba(30, 33, 32, 1)',
                        color: '#fff',
                        width: 150,
                        height: 50,
                        fontSize: 14,
                        textTransform: 'capitalize'
                      }}
                    >
                      {'Edit User'}
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Paper>
          ))}
          <Grid container>
            <Button
              onClick={() => navigate('/app/add-user')}
              disabled={disabled}
              style={{
                backgroundColor: 'rgba(30, 33, 32, 1)',
                color: '#fff',
                width: 150,
                height: 50,
                fontSize: 14,
                textTransform: 'capitalize',
                marginBottom: 20,
                opacity: disabled ? 0.6 : 1
              }}
            >
              {'Add User'}
            </Button>
          </Grid>
        </div>
      ) : (
        <Paper elevation={5} sx={{ width: '100%', mb: 5, mt: 2 }}>
          <Grid container>
            {packages.map((item, index) => (
              <Grid
                sx={{
                  backgroundColor: item.yearly ? '#000' : '#fff',
                  textAlign: 'center',
                  borderRight: '1px solid rgba(0, 0, 0, 0.1)'
                }}
                key={index}
                item
                md={3}
                xs={12}
              >
                {item.noPrice && (
                  <div className='discount'>
                    <div className='discountPrice'>{item.discount}</div>
                    <div className='discountText'>Discount</div>
                  </div>
                )}
                <div
                  style={{
                    display: 'flex',
                    // width: '100%',
                    justifyContent: 'center',
                    alignItems: 'center'
                  }}
                >
                  <p
                    className={
                      item.noPrice
                        ? 'titlenoPrice'
                        : item.yearly
                        ? 'titleYearly'
                        : 'titleMonthly'
                    }
                  >
                    {item.title}
                  </p>
                  {user?.subscribed !== 'canceled' &&
                    item.title === user?.subscription?.plan?.product?.name && (
                      <CheckCircleIcon
                        style={{
                          color: 'rgba(255, 145, 65, 1)',
                          marginTop: 20,
                          marginLeft: item.noPrice ? 15 : 30,
                          marginRight: item.noPrice ? -30 : -60
                        }}
                      />
                    )}
                </div>
                <p className={item.yearly ? 'priceYearly' : 'priceMonthly'}>
                  {item.price}
                </p>
                <p
                  className={item.yearly ? 'durationYearly' : 'durationMonthly'}
                >
                  {item.duration}
                </p>
                <Divider
                  sx={{
                    backgroundColor: 'rgba(255, 255, 255, 0.1)',
                    mt: 2,
                    mb: 2
                  }}
                />
                <div style={{ minHeight: 400 }}>
                  {item.data.map((res, index1) => (
                    <Grid key={index1} container sx={{ p: 1, pl: 2 }}>
                      <CheckCircleIcon
                        sx={{ color: item.yearly ? '#fff' : '#000', mr: 2 }}
                      />
                      <p
                        className={
                          item.yearly ? 'pointsYearly' : 'pointsMonthly'
                        }
                      >
                        {res}
                      </p>
                    </Grid>
                  ))}
                </div>
                <Button
                  onClick={() =>
                    // item.noPrice
                    //   ? window.open(
                    //       'https://www.trylander.com/#section-Y67uwl37N'
                    //     )
                    //   :
                    navigate(`/app/payment/${plans[index].id}`)
                  }
                  disabled={
                    user?.subscribed !== 'canceled' &&
                    item.title === user?.subscription?.plan?.product?.name
                  }
                  style={{
                    backgroundColor: item.yearly
                      ? 'rgba(255, 145, 65, 1)'
                      : 'rgba(30, 33, 32, 1)',
                    color: '#fff',
                    width: 150,
                    height: 50,
                    fontSize: 14,
                    textTransform: 'capitalize',
                    marginBottom: 20,
                    opacity:
                      user?.subscribed !== 'canceled' &&
                      item.title === user?.subscription?.plan?.product?.name
                        ? 0.6
                        : 1
                  }}
                >
                  {'Start Now'}
                </Button>
              </Grid>
            ))}
          </Grid>
        </Paper>
      )}
      <Dialog onClose={handleClose} open={open}>
        <Grid sx={{ p: 3 }}>
          <Grid container justifyContent={'flex-end'}>
            <Grid
              onClick={handleClose}
              sx={{
                backgroundColor: '#C4C4C44D',
                cursor: 'pointer',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                width: 30,
                height: 30,
                borderRadius: 30,
                mb: 2
              }}
            >
              <CloseIcon sx={{ color: '#A0A0A0' }} />
            </Grid>
          </Grid>
          <Grid container alignItems={'center'} justifyContent={'center'}>
            <p style={{ width: '80%', textAlign: 'center' }}>
              Are you sure want to delete user {userName}?
            </p>
          </Grid>
          <Grid
            container
            alignItems={'center'}
            justifyContent={'center'}
            sx={{ mt: 2 }}
          >
            <UIButton
              onClick={handleClose}
              sx={{
                width: '150px',
                height: 50,
                mr: 2,
                backgroundColor: '#E9E9E9'
              }}
            >
              Cancel
            </UIButton>
            <UIButton
              onClick={deleteEmployee}
              sx={{
                width: '150px',
                height: 50,
                my: 1,
                backgroundColor: 'rgba(30, 33, 32, 1)'
              }}
            >
              {loadingDelete ? (
                <CircularProgress style={{ color: '#fff' }} />
              ) : (
                'Delete'
              )}
            </UIButton>
          </Grid>
        </Grid>
      </Dialog>
      <Dialog onClose={handleClose1} open={open1}>
        <Grid sx={{ p: 3 }}>
          <Grid container justifyContent={'flex-end'}>
            <Grid
              onClick={handleClose1}
              sx={{
                backgroundColor: '#C4C4C44D',
                cursor: 'pointer',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                width: 30,
                height: 30,
                borderRadius: 30,
                mb: 2
              }}
            >
              <CloseIcon sx={{ color: '#A0A0A0' }} />
            </Grid>
          </Grid>
          <Grid container alignItems={'center'} justifyContent={'center'}>
            <p style={{ width: '80%', textAlign: 'center' }}>
              Are you sure you want to cancel your subscription? Please note
              that access to Lander services will cease after cancellation
            </p>
          </Grid>
          <Grid
            container
            alignItems={'center'}
            justifyContent={'center'}
            sx={{ mt: 2 }}
          >
            <UIButton
              onClick={handleClose1}
              sx={{
                width: '150px',
                height: 50,
                mr: 2,
                backgroundColor: '#E9E9E9'
              }}
            >
              No
            </UIButton>
            <UIButton
              onClick={_handleUnSubscribePlan}
              sx={{
                width: '150px',
                height: 50,
                my: 1,
                backgroundColor: 'rgba(30, 33, 32, 1)'
              }}
            >
              {loadingUnsubscribe ? (
                <CircularProgress style={{ color: '#fff' }} />
              ) : (
                'Yes'
              )}
            </UIButton>
          </Grid>
        </Grid>
      </Dialog>
    </WrapperContent>
  )
}
export default Price
