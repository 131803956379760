import * as React from 'react'
import { useNavigate } from 'react-router-dom'

import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import Radio from '@mui/material/Radio'
import RadioGroup from '@mui/material/RadioGroup'
import FormControlLabel from '@mui/material/FormControlLabel'
import FormControl from '@mui/material/FormControl'
import FormLabel from '@mui/material/FormLabel'

import { ReactComponent as CheckedIcon } from '@assets/icons/checked.svg'
import UIButton from '@components/UIButton'
import UICard from '@components/UICard'
import { ButtonJobContinue } from './PageJobCreate'
import { useDispatch, useSelector } from 'react-redux'
import {
  selectCategories,
  selectNewJob,
  updateJob
} from '@features/jobs/jobSlice'
import UITextField, { UITextFieldMask } from '@components/UITextField'
import { TextSubTitle } from './components'
import { UISwitch } from '@components/UISwitch'
import { updateSnack } from '@features/util/utilSlice'
// salary_type
// budget
// negotiated
// estimated_time
const JobSectionBudget = ({ onSubmit,loading, jobDetail }) => {
  // const newJob = useSelector(selectNewJob);
  const { salary_type, budget, negotiated, visible } = useSelector(selectNewJob)
  const dispatch = useDispatch()

  React.useEffect(() => {
    if (jobDetail) {
      handleChange('salary_type')(jobDetail?.salary_type)
      handleChange('budget')(jobDetail?.budget)
      handleChange('negotiated')(jobDetail?.negotiated||false)
      handleChange('visible')(jobDetail?.visible || false)
    }
  }, [jobDetail])

  const handleChange = key => value => {
    if(key === 'budget'){
      value = Math.max(0, parseInt(value) ).toString().slice(0,7)
    }
    dispatch(updateJob({ key, value }))
    dispatch(
      updateSnack({
        open: false
      })
    )
  }
  const handleChangeChecked = key => event => {
    handleChange(key)(event.target.checked)
  }
  const handleChangeValue = key => event => {
    handleChange(key)(event.target.value)
  }
  const handleSubmit = () => {
    if (!salary_type) {
      dispatch(
        updateSnack({
          open: true,
          severity: 'error',
          message: 'Please select a salary type'
        })
      )
    } else if (!budget) {
      dispatch(
        updateSnack({
          open: true,
          severity: 'error',
          message: 'Please enter an adequate budget'
        })
      )
    } else {
      onSubmit()
    }
  }
  return (
    <>
      <Box>
        <TextSubTitle>Salary type</TextSubTitle>
        <RadioGroup
          id='salary_type'
          row
          aria-label='salary type'
          name='salary-type-group'
          value={salary_type || ''}
          onChange={handleChangeValue('salary_type')}
        >
          <FormControlLabel
            value='Salary Exempt'
            control={<Radio />}
            label='Salary Exempt'
          />
          <FormControlLabel
            value='Salary Non-exempt'
            control={<Radio />}
            label='Salary Non-exempt'
          />
          <FormControlLabel value='Hourly' control={<Radio />} label='Hourly' />
        </RadioGroup>
        <Grid item xs={5} sx={{ display: 'flex', alignItems: 'center', mt: 1 }}>
          <UITextField
            id='budget'
            value={budget}
            placeholder='Budget'
            // icon={<MessageIcon />}
            type='number'
            
            onChange={handleChange('budget')}
            // opts={{
            //   mask: Number, // enable number mask
            //   // other options are optional with defaults below
            //   scale: 2, // digits after point, 0 for integers
            //   signed: false, // disallow negative
            //   thousandsSeparator: ',', // any single char
            //   padFractionalZeros: false, // if true, then pads zeros at end to the length of scale
            //   normalizeZeros: true, // appends or removes zeros at ends
            //   // radix: ',', // fractional delimiter
            //   // mapToRadix: ['.'], // symbols to process as radix
            //   // additional number interval options (e.g.)
            //   min: 0,
            //   // max: 10000,
            // }}
            // error={errors?.email}
          />
          <Typography sx={{ fontWeight: '500', fontSize: '1.125rem', ml: 1 }}>
            $
          </Typography>
        </Grid>
        <Box>
          <FormControlLabel
            id='negotiated'
            // control={<UISwitch defaultChecked />}
            control={<UISwitch />}
            sx={{ ml: 0, mt: 1 }}
            label='Negotiable'
            // labelPlacement="start"
            checked={negotiated}
            onChange={handleChangeChecked('negotiated')}
          />
        </Box>
        <Box>
          <FormControlLabel
            id='visible'
            control={<UISwitch />}
            sx={{ ml: 0, mt: 1 }}
            label='Visible'
            // labelPlacement="start"
            checked={visible}
            onChange={handleChangeChecked('visible')}
          />
        </Box>
      </Box>
      <ButtonJobContinue loading={loading} onSubmit={handleSubmit} />
    </>
  )
}

export default JobSectionBudget
