import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import {
  addPayMethod,
  apiGetPaymentMethod,
  apiGetPlans,
  changePlan,
  deletePayMethod,
  subscribePlan,
  unsubscribePlan
} from './priceAPI'

const initialState = {
  status: 'idle',
  plans: [],
  paymentMethod: []
}

export const getPlans = createAsyncThunk(
  'price/getPlans',
  async (payload, { rejectWithValue, getState }) => {
    try {
      const { token } = getState().auth
      const { data, status } = await apiGetPlans()(token)

      if (status === 201 || status === 200) {
        console.log('getPlansdata', data)
        return data
      }
      return rejectWithValue(data)
    } catch (err) {
      console.log('[dev] price/getPlans::response::catch', err)
    }
  }
)

export const getPaymentMethod = createAsyncThunk(
  'price/getPaymentMethod',
  async (payload, { rejectWithValue, getState }) => {
    try {
      const { token } = getState().auth
      const { data, status } = await apiGetPaymentMethod()(token)

      if (status === 201 || status === 200) {
        console.log('getPaymentMethoddata', data)
        return data
      }
      return rejectWithValue(data)
    } catch (err) {
      console.log('[dev] price/getPlans::response::catch', err)
    }
  }
)

export const _addPayMethod = createAsyncThunk(
  'price/_addPayMethod',
  async (payload, { rejectWithValue, getState }) => {
    try {
      const { token } = getState().auth
      const { data, status } = await addPayMethod(payload)(token)

      if (status === 201 || status === 200) {
        console.log('getPaymentMethoddata', data)
        return data
      }
      return rejectWithValue(data)
    } catch (err) {
      console.log('[dev] price/getPlans::response::catch', err)
    }
  }
)

export const _deletePayMethod = createAsyncThunk(
  'price/_deletePayMethod',
  async (payload, { rejectWithValue, getState }) => {
    try {
      const { token } = getState().auth
      const { data, status } = await deletePayMethod(payload)(token)

      if (status === 201 || status === 200) {
        console.log('getPaymentMethoddata', data)
        return data
      }
      return rejectWithValue(data)
    } catch (err) {
      console.log('[dev] price/getPlans::response::catch', err)
    }
  }
)

export const _subscribePlan = createAsyncThunk(
  'price/_subscribePlan',
  async (payload, { rejectWithValue, getState }) => {
    try {
      console.log('payload', payload)
      const { token } = getState().auth
      const { data, status } = await subscribePlan(payload)(token)

      if (status === 201 || status === 200) {
        console.log('_subscribePlan', data)
        // alert(`You have successfully subscribed!`)
        return { data: data, status: status }
      }
      return rejectWithValue(data)
    } catch (err) {
      console.log('[dev] price/getPlans::response::catch', err)
    }
  }
)

export const _unsubscribePlan = createAsyncThunk(
  'price/_unsubscribePlan',
  async (payload, { rejectWithValue, getState }) => {
    try {
      const { token } = getState().auth
      const { data, status } = await unsubscribePlan()(token)

      if (status === 201 || status === 200) {
        console.log('_unsubscribePlan', data)
        return { data: data, status: status }
      }
      return rejectWithValue(data)
    } catch (err) {
      console.log('[dev] price/getPlans::response::catch', err)
    }
  }
)

export const _changePlan = createAsyncThunk(
  'price/_changePlan',
  async (payload, { rejectWithValue, getState }) => {
    try {
      console.log('payload', payload)
      const { token } = getState().auth
      const { data, status } = await changePlan(payload)(token)

      if (status === 201 || status === 200) {
        console.log('_changePlan', data)
        return { data: data, status: status }
      }
      return rejectWithValue(data)
    } catch (err) {
      console.log('[dev] price/getPlans::response::catch', err)
    }
  }
)

export const priceSlice = createSlice({
  name: 'price',
  initialState,
  reducers: {
    extraReducers: builder => {
      builder
        .addCase(getPlans.pending, state => {
          state.status = 'loading'
        })
        .addCase(getPlans.fulfilled, (state, action) => {
          state.plans = action.payload
          state.status = 'idle'
        })
        .addCase(getPaymentMethod.pending, state => {
          state.status = 'loading'
        })
        .addCase(getPaymentMethod.fulfilled, (state, action) => {
          state.paymentMethod = action.payload
          state.status = 'idle'
        })
        .addCase(_addPayMethod.pending, state => {
          state.status = 'loading'
        })
        .addCase(_addPayMethod.fulfilled, (state, action) => {
          state.paymentMethod = action.payload
          state.status = 'idle'
        })
        .addCase(_deletePayMethod.pending, state => {
          state.status = 'loading'
        })
        .addCase(_deletePayMethod.fulfilled, (state, action) => {
          state.paymentMethod = action.payload
          state.status = 'idle'
        })
        .addCase(_subscribePlan.pending, state => {
          state.status = 'loading'
        })
        .addCase(_subscribePlan.fulfilled, (state, action) => {
          state.paymentMethod = action.payload
          state.status = 'idle'
        })
        .addCase(_unsubscribePlan.pending, state => {
          state.status = 'loading'
        })
        .addCase(_unsubscribePlan.fulfilled, (state, action) => {
          state.status = 'idle'
        })
        .addCase(_changePlan.pending, state => {
          state.status = 'loading'
        })
        .addCase(_changePlan.fulfilled, (state, action) => {
          state.paymentMethod = action.payload
          state.status = 'idle'
        })
    }
  }
})

// export const {  } = priceSlice.actions

export const selectedPlans = state => state.price.plans

export default priceSlice.reducer
