import { apiFetch } from '@utils/apiFetch'

const authAPI = apiFetch

// curl --location --request POST 'https://ideapros-llc-lander-30616.botics.co/api/v1/user/login/' \
// --form 'email="sallar.rezaie@crowdbotics.com"' \
// --form 'password="testpass321"'
export function apiLoginRequest (payload) {
  return authAPI.post(`/api/v1/user/login/`, payload)
}

// curl --location --request POST 'https://ideapros-llc-lander-30616.botics.co/api/v1/user/' \
// --form 'email="sallar.rezaie@crowdbotics.com"' \
// --form 'password="testpass123"' \
// --form 'first_name="Sallar"' \
// --form 'last_name="Rezaie"' \
// --form 'type="Candidate"' \
// --form 'candidate.contact_number="4169944347"'
export function apiSignupRequest (payload) {
  return authAPI.post(`/api/v1/user/`, payload)
}

export function apiForgotPassRequest (payload) {
  return authAPI.post(`/api/v1/user/reset/`, payload)
}

// curl --location --request PATCH 'https://ideapros-llc-lander-30616.botics.co/api/v1/user/8/' \
// --header 'Authorization: Token ba9564c639a55e3da2f34a233e3bfcbb987e2803' \
// --form 'first_name="Sal"' \
// --form 'last_name="Rezaie"' \
// --form 'candidate.contact_number="6472047173"' \
// --form 'candidate.photo=@"/C:/Users/NEXT/Documents/male1.jpg"' \
// --form 'candidate.city="Toronto"' \
// --form 'candidate.zip_code="M2N0A2"' \
// --form 'candidate.video=@"/C:/Users/NEXT/Downloads/big_buck_bunny_480p_1mb.flv"' \
// --form 'candidate.resume_file=@"/C:/Users/NEXT/Documents/Consent_Form_Eng.pdf"'

export function apiUpdateProfileRequest (payload) {
  const { id, body, type = 'none' } = payload
  return apiFetch.patch(`/api/v1/user/${id}/`, body, type)
}

export function apiDeleteProfileRequest (payload) {
  const { id, type = 'none' } = payload
  return apiFetch.delete(`/api/v1/user/${id}/`, {}, type)
}

export function apiUserRequest (id) {
  return apiFetch.get(`/api/v1/user/${id}/`)
}

// https://ideapros-llc-lander-30616.botics.co/api/v1/dropdowns/positions/
export function apiPositionsRequest (id) {
  return apiFetch.get(`/api/v1/dropdowns/positions/`)
}

// https://ideapros-llc-lander-30616.botics.co/api/v1/dropdowns/industries/
export function apiIndustriesRequest (id) {
  return apiFetch.get(`/api/v1/dropdowns/industries/`)
}

// https://ideapros-llc-lander-30616.botics.co/api/v1/dropdowns/industries/
export function apiSocialAuthRequest (payload) {
  const { provider, ...body } = payload
  return apiFetch.post(`/modules/social-auth/${provider}/login/`, body)
}

export function apiConversationsRequest () {
  return apiFetch.get(`/api/v1/conversations/?name=`)
}

export function apiUserSearchByIdsRequest (ids) {
  return apiFetch.get(`/api/v1/user-search/?ids=${ids}`)
}

export function apiStartConversationsRequest (payload) {
  const { ...body } = payload
  return apiFetch.post(`/api/v1/conversations/`, body)
}

export function apiUpdateConversationsRequest (id, payload) {
  const { ...body } = payload
  return apiFetch.patch(`/api/v1/conversations/${id}/`, body)
}

export function apiSendMessageRequest (payload) {
  const { ...body } = payload
  return apiFetch.post(`/api/v1/conversations/send_email/`, body)
}

export function apiReadMessageRequest (payload) {
  const { ...body } = payload
  return apiFetch.post(`/api/v1/conversations/read/`, body)
}

export function apiResetPasswordRequest (payload) {
  const { ...body } = payload
  return apiFetch.post(`/api/v1/user/password/`, body)
}
