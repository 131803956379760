import * as React from 'react';
import UIButton from '@components/UIButton';
import { Box, Button, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';

import { ReactComponent as SetupProfileSvg } from '@assets/icons/imgProfile.svg';
import { useSelector } from 'react-redux';
import { selectUser } from '@features/auth/authSlice';
import PageUploadImage from './PageUploadImage';
import PageLocation from './PageLocation';
import PageUploadVideo from './PageUploadVideo';
import PageFinishedCompany from './PageFinishedCompany';
import PageFinishedCandidate from './PageFinishedCandidate';

const PageProfileSetup = () => {
  const user = useSelector(selectUser);
  const [step, setStep] = React.useState(0);

  const handleNext = () => {
    setStep((s) => s + 1);
  };

  const stepList = [
    <PageUploadImage onNext={handleNext} />,
    <PageLocation onNext={handleNext} />,
  ];

  if (user?.type === 'Company') {
    stepList.push(<PageFinishedCompany />);
  } else {
    stepList.push(<PageUploadVideo onNext={handleNext} />);
    stepList.push(<PageFinishedCandidate />);
  }

  return stepList[step];
};

export default PageProfileSetup;
