import * as React from 'react'
import UIButton from '@components/UIButton'
import {
  Avatar,
  Box,
  Button,
  Divider,
  Grid,
  Paper,
  Typography,
  Radio,
  Dialog,
  DialogActions,
  FormControl,
  CircularProgress,
  IconButton,
  Popover
} from '@mui/material'
import { useNavigate, useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import WrapperContent from '@components/WrapperContent'
import VISA from '../../assets/images/visa.png'
import mastercard from '../../assets/images/mastercard.png'
import {
  candidatesRequest,
  selectCandidates
} from '@features/candidates/candidateSlice'
import CloseIcon from '@mui/icons-material/Close'
import {
  getPaymentMethod,
  _changePlan,
  _deletePayMethod,
  _subscribePlan
} from '@features/price/priceSlice'
import { useState } from 'react'
import { updateSnack } from '@features/util/utilSlice'
import {
  selectUser,
  selectCurrentUser,
  currentProfileRequest
} from '@features/auth/authSlice'
import UIDialog from '@components/UIDialog'

const AddCard = () => {
  const navigate = useNavigate()
  const [open, setOpen] = React.useState(false)
  const [anchorEl, setAnchorEl] = React.useState(null)
  const dispatch = useDispatch()
  const authUser = useSelector(selectUser)
  const user = useSelector(selectCurrentUser)
  const { id } = useParams()
  const [state, setState] = useState({
    paymentMethod: [],
    selectedValue: '',
    loadingDelete: false,
    loading: false,
    headingText: '',
    modalText: '',
    modalOpen: false
  })
  const {
    paymentMethod,
    selectedValue,
    loadingDelete,
    loading,
    modalOpen,
    modalText,
    headingText
  } = state
  React.useEffect(() => {
    _getPaymentMethod()
  }, [dispatch])

  const _getPaymentMethod = async () => {
    const res = await dispatch(getPaymentMethod())
    handleChange('paymentMethod', res.payload?.data)
  }

  const handleChange = (key, value) => {
    setState(pre => ({ ...pre, [key]: value }))
  }

  const handleModalClose = () => {
    handleChange('modalOpen', false)
    handleChange('modalText', '')
    handleChange('headingText', '')
  }

  const handleClickOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  const _deletePaymentMethodCheck = async () => {
    if (
      paymentMethod?.length === 1 &&
      (user?.subscription || user?.subscribed !== 'canceled')
    ) {
      handleClickOpen()
    } else {
      _deletePaymentMethod()
    }
  }
  const _deletePaymentMethod = async () => {
    try {
      handleChange('loadingDelete', true)
      const payload = {
        payment_method: selectedValue
      }
      await dispatch(_deletePayMethod(payload))
      handleChange('loadingDelete', false)
      handleChange('selectedValue', '')
      handleChange('modalText', 'Card has been deleted')
      handleChange('headingText', 'SUCCESS')
      handleChange('modalOpen', true)
      _getPaymentMethod()
    } catch (error) {
      handleChange('loadingDelete', false)
      const errorText = Object.values(error?.response?.data)
      alert(`Error: ${errorText}`)
    }
  }

  const _handleSubscribePlan = async () => {
    try {
      if (!selectedValue) {
        dispatch(
          updateSnack({
            open: true,
            severity: 'error',
            message: 'Please select card!'
          })
        )
        return
      }
      handleChange('loading', true)
      const payload = {
        payment_method: selectedValue,
        plan_id: id
      }
      const res = await dispatch(_subscribePlan(payload))
      console.log('res', res)
      if (res?.payload?.status === 201 || res?.payload?.status === 200) {
        handleChange('loading', false)
        handleChange('selectedValue', '')
        dispatch(
          updateSnack({
            open: true,
            severity: 'success',
            message: !user?.subscription
              ? 'Trial period active. Card will not be charged until trial period expires'
              : 'You have successfully subscribed'
          })
        )
        dispatch(currentProfileRequest(authUser?.id))
        navigate('/app/price')
      } else {
        handleChange('loading', false)
        alert('Something went wrong!')
      }
    } catch (error) {
      handleChange('loading', false)
      const errorText = Object.values(error?.response?.data)
      alert(`Error: ${errorText}`)
    }
  }

  const _handleChangePlan = async () => {
    try {
      handleChange('loading', true)
      const payload = {
        plan_id: id
      }
      const res = await dispatch(_changePlan(payload))
      console.log('res', res)
      if (res?.payload?.status === 201 || res?.payload?.status === 200) {
        handleChange('loading', false)
        handleChange('selectedValue', '')
        dispatch(currentProfileRequest(authUser?.id))
        dispatch(
          updateSnack({
            open: true,
            severity: 'success',
            message: 'You have successfully changed subscription'
          })
        )
        navigate('/app/price')
      } else {
        handleChange('loading', false)
        handleChange('modalText', res?.payload || 'Something went wrong!')
        handleChange('headingText', 'CAUTION')
        handleChange('modalOpen', true)
      }
    } catch (error) {
      handleChange('loading', false)
      const errorText = Object.values(error?.response?.data)
      alert(`Error: ${errorText}`)
    }
  }

  const handleClick = event => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose1 = () => {
    setAnchorEl(null)
  }

  console.log('user', user)
  const open1 = Boolean(anchorEl)
  const _id = open1 ? 'simple-popover' : undefined
  return (
    <WrapperContent
      label='Payment'
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column'
      }}
      actions={
        <Button
          onClick={() => navigate('/app/add-card')}
          style={{
            backgroundColor: '#000',
            color: '#fff',
            width: 150,
            height: 50,
            fontSize: 14,
            textTransform: 'capitalize',
            marginBottom: 20
          }}
        >
          Add Card
        </Button>
      }
    >
      <Paper elevation={0} sx={{ width: '100%', mb: 5, p: 3, borderRadius: 5 }}>
        <div className='rowItem'>
          <Typography variant='h5'>List of Cards</Typography>
          <Button
            aria-describedby={_id}
            className='gPopup1'
            onClick={handleClick}
          >
            I
          </Button>
        </div>
        <Popover
          id={_id}
          open={open1}
          anchorEl={anchorEl}
          style={{ width: '100%', top: 50, position: 'absolute' }}
          onClose={handleClose1}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left'
          }}
        >
          <div className='rowBetween'>
            <Typography sx={{ p: 2, fontSize: 12, color: 'red' }}>
              For first time Lander account, card will not be charged until
              trial period expires
            </Typography>
            <IconButton onClick={handleClose1}>
              <CloseIcon />
            </IconButton>
          </div>
        </Popover>
        <Divider sx={{ mt: 2, mb: 2 }} />
        {paymentMethod?.map((method, index) => (
          <Grid key={index} container alignItems={'center'} sx={{ mb: 1 }}>
            <Radio
              checked={selectedValue === method?.id}
              onChange={event =>
                handleChange('selectedValue', event.target.value)
              }
              value={method?.id}
              name='radio-buttons'
              inputProps={{ 'aria-label': 'A' }}
            />
            <img
              src={method?.card?.brand === 'mastercard' ? mastercard : VISA}
              width={40}
              style={{ width: 40, objectFit: 'contain' }}
            />
            <p style={{ marginLeft: 10, marginRight: 20 }}>
              **** **** **** {method?.card?.last4}
            </p>
            <p style={{ color: 'grey' }}>
              Expires {method?.card?.exp_month}/{method?.card?.exp_year}
            </p>
          </Grid>
        ))}
      </Paper>
      <Grid container sx={{ mb: 3 }}>
        <UIButton
          onClick={_deletePaymentMethodCheck}
          disabled={!selectedValue}
          sx={{
            width: '150px',
            height: 50,
            mr: 2,
            backgroundColor: 'rgba(253, 64, 58, 1)'
          }}
        >
          {loadingDelete ? (
            <CircularProgress
              style={{ color: '#fff', width: 20, height: 20 }}
            />
          ) : (
            ' Delete Card'
          )}
        </UIButton>
        <UIButton
          onClick={
            !user?.subscription || user?.subscribed === 'canceled'
              ? _handleSubscribePlan
              : _handleChangePlan
          }
          disabled={
            user?.subscription || user?.subscribed !== 'canceled'
              ? false
              : !id || !selectedValue
          }
          sx={{
            width: '150px',
            height: 50,
            my: 1,
            backgroundColor: 'rgba(30, 33, 32, 1)'
          }}
        >
          {loading ? (
            <CircularProgress
              style={{ color: '#fff', width: 20, height: 20 }}
            />
          ) : (
            'Start Hiring'
          )}
        </UIButton>
      </Grid>
      <Dialog onClose={handleClose} open={open}>
        <Grid sx={{ p: 3 }}>
          <Grid container justifyContent={'flex-end'}>
            <Grid
              onClick={handleClose}
              sx={{
                backgroundColor: '#C4C4C44D',
                cursor: 'pointer',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                width: 30,
                height: 30,
                borderRadius: 30,
                mb: 2
              }}
            >
              <CloseIcon sx={{ color: '#A0A0A0' }} />
            </Grid>
          </Grid>
          <Grid container alignItems={'center'} justifyContent={'center'}>
            <p style={{ width: '80%', textAlign: 'center' }}>
              You have a subscription that requires one valid card on file.
              Please ensure there is one valid card on file in order for your
              subscription to continue
            </p>
          </Grid>
          <Grid
            container
            alignItems={'center'}
            justifyContent={'center'}
            sx={{ mt: 2 }}
          >
            <UIButton
              onClick={handleClose}
              sx={{
                width: '150px',
                height: 50,
                mr: 2,
                backgroundColor: '#E9E9E9'
              }}
            >
              Cancel
            </UIButton>
            <UIButton
              onClick={_deletePaymentMethod}
              sx={{
                width: '150px',
                height: 50,
                my: 1,
                backgroundColor: 'rgba(30, 33, 32, 1)'
              }}
            >
              {loadingDelete ? (
                <CircularProgress style={{ color: '#fff' }} />
              ) : (
                'Delete'
              )}
            </UIButton>
          </Grid>
        </Grid>
      </Dialog>
      <UIDialog
        text={modalText}
        open={modalOpen}
        handleClose={handleModalClose}
        headingText={headingText}
      />
    </WrapperContent>
  )
}
export default AddCard
