import * as React from 'react';

import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import UIButton from '@components/UIButton';

export const TextTitle = ({ sx, ...props }) => (
  <Typography
    sx={{
      fontWeight: '500',
      fontSize: '1.3125rem', // 21px
      ...sx,
      mt: 2,
    }}
    {...props}
  ></Typography>
);

export const TextSubTitle = ({ sx, ...props }) => (
  <Typography
    sx={{
      fontWeight: '400',
      fontSize: '1rem',
      color: 'rgba(0, 0, 0, 0.5)',
      mt: 3,
      mb: 1,
      ...sx,
    }}
    {...props}
  ></Typography>
);

export const CategoryChip = ({
  sx = {},
  sxt = {},
  small = false,
  children,
}) => (
  <Grid item>
    <Box
      sx={{
        position: 'relative',
        // width: '200px',
        // height: '70px',
        py: 1,
        px: 2,
        borderRadius: '10px',
        // backgroundColor: 'secondary.main',
        backgroundColor: '#FF914120',
        // backgroundColor: '#FFF4EC',
        // opacity: '0.1',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        // border: '1px solid rgba(0, 0, 0, 0.1)',
        // cursor: 'pointer',
        userSelect: 'none',
        ':hover': {
          // backgroundColor: '#FF914130',
          backgroundColor: '#FF914130',
        },

        // ...((active && {
        //   backgroundColor: 'wite',
        //   border: '1px solid rgba(0, 0, 0, 0.0)',
        //   boxShadow: '1px 3px 20px rgba(24, 20, 88, 0.06)',
        // }) ||
        //   {}),
        ...sx,
      }}
    >
      <Typography
        sx={{
          //
          // py: 2,
          fontWeight: '500',
          fontSize: small ? '0.75rem' : '1.125rem',
          // textAlign: 'center',
          color: 'secondary.main',
          ...sxt,
        }}
      >
        {children}
      </Typography>
    </Box>
  </Grid>
);

export const JobCategoryButton = ({ sx, active, ...props }) => {
  return (
    <UIButton
      sx={{
        //
        py: 1,
        m: 1,
        ...((!active && {
          backgroundColor: '#F4F4F4',
          color: '#000',
          // border: '1px solid rgba(0, 0, 0, 0.0)',
          boxShadow: '1px 3px 20px rgba(24, 20, 88, 0.06)',
          ':hover': {
            color: '#FFF',
          },
        }) ||
          {}),
        ...sx,
      }}
      {...props}
    />
  );
};
