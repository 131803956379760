import * as React from 'react'
import moment from 'moment'
import { useNavigate } from 'react-router-dom'
import { useLocation } from 'react-router'

import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import Avatar from '@mui/material/Avatar'

import { ReactComponent as LocationIcon } from '@assets/icons/location.svg'
import UIButton from '@components/UIButton'
import UICard from '@components/UICard'
import JobSectionCategory from './JobSectionCategory'
import { useDispatch, useSelector } from 'react-redux'
import { unwrapResult } from '@reduxjs/toolkit'
import {
  jobApplyRequest,
  jobDetailRequest,
  selectIsLoadingJob,
  selectJobDetail
} from '@features/jobs/jobSlice'
import JobSectionName from './JobSectionName'
import JobSectionDescription from './JobSectionDescription'
import JobSectionBudget from './JobSectionBudget'
import PageConcratulationJob from './PageConcratulationJob'
import UILoading from '@components/UILoading'
import { CategoryChip } from './components'
import { useUserType } from '@utils/common'
import { updateSnack } from '@features/util/utilSlice'
import UIFiles from '@components/UIFiles'

const TextSubTitle = ({ sx, ...props }) => (
  <Typography
    sx={{
      fontWeight: '400',
      fontSize: '1rem',
      color: 'rgba(0, 0, 0, 0.5)',
      mt: 3,
      mb: 1,
      ...sx
    }}
    {...props}
  ></Typography>
)

const TextBodyTitle = ({ sx, ...props }) => (
  <Typography
    sx={{
      fontWeight: '400',
      fontSize: '1.125rem',
      ...sx
    }}
    {...props}
  ></Typography>
)
const AppliedList = ({ applied }) => {
  const navigate = useNavigate()
  return (
    <Box
      sx={{
        display: 'flex',
        flexWrap: 'wrap',
        '> *': {
          m: 1
        }
      }}
    >
      {applied?.map(({ id, first_name, last_name, candidate }) => {
        return (
          <Box
            key={`candidate-${id}`}
            sx={{
              // width: '198px',
              // height: '227px',
              py: 2,
              px: 2,
              border: '1px solid rgba(0, 0, 0, 0.1)',
              borderRadius: '10px',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center'
            }}
          >
            <Avatar
              src={candidate?.photo}
              sx={{
                width: '90px',
                height: '90px'
              }}
            />
            <Typography
              sx={{
                pt: 1,
                fontSize: '1rem',
                fontWeight: '500'
              }}
            >{`${first_name} ${last_name}`}</Typography>
            <Typography
              sx={{
                fontSize: '0.75rem', // 12px
                fontWeight: '400',
                color: 'rgba(0,0,0,0.5)',
                pb: 1
              }}
            >
              {candidate?.current_company_position || 'Unemployed'}
            </Typography>
            <UIButton
              sx={{
                //
                py: 1,
                m: 0,
                fontSize: '0.875rem' // 14px
              }}
              onClick={() => {
                navigate(`/app/profile/${id}`)
              }}
            >
              View Profile
            </UIButton>
          </Box>
        )
      })}
    </Box>
  )
}

const PageJobDetail = ({ jobId }) => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const userType = useUserType()
  const jobDetail = useSelector(selectJobDetail)
  const isLoading = useSelector(selectIsLoadingJob)
  const { state } = useLocation() //edit mode - state = True
  const [editMode, setEditMode] = React.useState(state?.isEdit)
  const {
    name,
    description,
    salary_type,
    estimated_time,
    created_at,
    views,
    city,
    looking_for,
    role,
    locations,
    posted,
    applied_candidates,
    company,
    budget,
    files: initFiles
  } = jobDetail
  const isCompany = userType === 'company' || userType === 'employee'

  const [files, setFiles] = React.useState()

  React.useEffect(() => {
    setFiles(initFiles)
  }, [initFiles])

  React.useEffect(() => {
    if (jobId) {
      dispatch(jobDetailRequest(jobId)).then(() => {
        setFiles(initFiles)
      })
    }
  }, [dispatch, jobId])

  const handleMessage = () => {
    navigate(`/app/chat/${company?.id}`)
  }
  const handleApply = () => {
    dispatch(jobApplyRequest(jobId))
      .then(unwrapResult)
      .then(data => {
        if (data?.detail) {
          dispatch(
            updateSnack({
              open: true,
              severity: 'success',
              message: data?.detail
            })
          )
        }
      })
  }

  const showValue = value => {
    if (isLoading) return <UILoading />
    return value
  }
  return (
    <UICard
      sx={{
        display: 'flex',
        flexDirection: 'column',
        flexGrow: 1
        // p: 2,
      }}
    >
      <Grid
        container
        sx={{
          flexGrow: 1
        }}
      >
        <Grid
          item
          xs={isCompany ? 12 : 9}
          container
          sx={{
            //
            display: 'flex',
            p: 2,
            borderRight: isCompany ? 'none' : '1px solid rgba(0, 0, 0, 0.1)'
          }}
        >
          <Grid item xs={12}>
            {/* <TextSubTitle>{(isLoading && <UILoading />) || name}</TextSubTitle> */}
            <TextSubTitle sx={{ mt: 0 }}>Job Title</TextSubTitle>
            <TextBodyTitle>{showValue(name)}</TextBodyTitle>
          </Grid>

          <Grid item xs={12}>
            <TextSubTitle>Job Description</TextSubTitle>
            <TextBodyTitle>{showValue(description)}</TextBodyTitle>
          </Grid>

          <Grid item xs={4}>
            <TextSubTitle>Salary type</TextSubTitle>
            <TextBodyTitle>{showValue(salary_type)}</TextBodyTitle>
          </Grid>
          <Grid item xs={4}>
            <TextSubTitle>Salary</TextSubTitle>
            <TextBodyTitle>{showValue(budget)}</TextBodyTitle>
          </Grid>
          <Grid item xs={4}>
            <TextSubTitle>Employment</TextSubTitle>
            <TextBodyTitle>{showValue(estimated_time)}</TextBodyTitle>
          </Grid>
          <Grid item xs={4}>
            <TextSubTitle>Posted</TextSubTitle>
            <TextBodyTitle>{showValue(posted)}</TextBodyTitle>
          </Grid>
          <Grid item xs={4}>
            <TextSubTitle>View</TextSubTitle>
            <TextBodyTitle>
              {showValue(`${views} Candidate${views > 1 ? 's' : ''}`)}
            </TextBodyTitle>
          </Grid>
          <Grid item xs={4}>
            <TextSubTitle>Location</TextSubTitle>
            <TextBodyTitle>
              {showValue(`${(locations?.length > 0 && locations.join(', ')) || ''}`)}
            </TextBodyTitle>
          </Grid>
          <Grid item xs={12}>
            <TextSubTitle>Tags</TextSubTitle>
            {showValue(
              <Grid container spacing={2}>
                {looking_for && (
                  <CategoryChip small>{looking_for}</CategoryChip>
                )}
                {/* {role && <CategoryChip small>{role}</CategoryChip>} */}
              </Grid>
            )}
          </Grid>
          <Grid item xs={12}>
            <TextSubTitle>Files</TextSubTitle>
            {showValue(files && <UIFiles files={files} setFiles={setFiles} />)}
          </Grid>
          <Grid item xs={12}>
            <TextSubTitle>Applied candidates</TextSubTitle>

            {showValue(
              isCompany && <AppliedList applied={applied_candidates} />
            ) || (
              <TextBodyTitle>
                {showValue(
                  applied_candidates &&
                    `${applied_candidates?.length}
              Candidate${applied_candidates?.length > 1 ? 's' : ''}`
                )}
              </TextBodyTitle>
            )}
          </Grid>
          {!isCompany && (
            <Grid item xs={4}>
              <UIButton
                onClick={handleApply}
                color='primary'
                sx={{
                  //
                  py: 1,
                  px: 4,
                  my: 3,
                  fontSize: '0.875rem' //14 px
                }}
              >
                Apply for this job
              </UIButton>
            </Grid>
          )}
        </Grid>
        {!isCompany && (
          <Grid
            //
            item
            xs={3}
            sx={{ p: 2 }}
          >
            <TextSubTitle sx={{ mt: 0 }}>About this manager</TextSubTitle>
            <Typography
              sx={{
                fontWeight: '500',
                fontSize: '1.25rem' // 20px
              }}
            >
              {`${company?.first_name} ${company?.last_name}`}
            </Typography>
            <Typography
              sx={{
                fontWeight: '400',
                fontSize: '0.875rem', //14 px
                color: 'rgba(0, 0, 0, 0.5)',
                py: 1
              }}
            >
              {company?.company?.position}
            </Typography>
            <Typography
              sx={{
                fontWeight: '500',
                fontSize: '0.875rem',
                display: 'flex',
                alignItems: 'center'
              }}
            >
              <LocationIcon height={20} width={20} />
              {`${(company?.company?.zip_code &&
                `${company?.company?.zip_code}, `) ||
                ''}${company?.company?.city}`}
            </Typography>
            <UIButton
              onClick={handleMessage}
              color='primary'
              sx={{
                //
                py: 1,
                px: 4,
                my: 3,
                fontSize: '0.875rem', //14 px
                flexGrow: 1
              }}
            >
              Message
            </UIButton>
          </Grid>
        )}
      </Grid>
    </UICard>
  )
}

export default PageJobDetail
