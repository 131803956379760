import * as React from 'react';

import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import UITextField from '@components/UITextField';

export const ProfileTextTitle = ({ sx, ...props }) => (
  <Typography
    sx={{
      fontWeight: '400',
      fontSize: '1.125rem', // 18px
      color: 'rgba(0, 0, 0, 0.5)',
      mt: 2,
      mb: 1,
      ...sx,
    }}
    {...props}
  ></Typography>
);

export const ProfileTextSubTitle = ({ sx, ...props }) => (
  <Typography
    sx={{
      fontWeight: '400',
      fontSize: '1.25rem', // 20px
      // mt: 3,
      // mb: 1,
      ...sx,
    }}
    {...props}
  ></Typography>
);

export const ProfileBoxWrap = ({ sx = {}, children }) => {
  return (
    <Box
      sx={{
        //
        p: 2,
        display: 'flex',
        flexDirection: 'column',
        borderBottom: '1px solid rgba(0, 0, 0, 0.1)',
        ...sx,
      }}
    >
      {children}
    </Box>
  );
};
export const ProfileBox = ({ sx = {}, xs = 9, children }) => {
  return (
    <ProfileBoxWrap>
      <Grid item xs={xs}>
        {children}
      </Grid>
    </ProfileBoxWrap>
  );
};

export const ProfileTextSection = ({ label, ...props }) => {
  return (
    <>
      <ProfileTextTitle>{label}</ProfileTextTitle>
      <EditModeWrap {...props} />
    </>
  );
};

export const EditModeWrap = ({
  value,
  defaultValue,
  editMode,
  children,
  ...props
}) => {
  if (editMode) {
    return (
      children || (
        <UITextField value={value} defaultValue={defaultValue} {...props} />
      )
    );
  }
  return <ProfileTextSubTitle>{value || defaultValue}</ProfileTextSubTitle>;
};
export const ProfileTextSmallSection = ({
  label,
  value,
  editMode,
  ...props
}) => {
  return (
    <Box sx={{ flex: '1 1 0', mx: 1 }}>
      {label}
      <EditModeWrap
        value={value}
        editMode={editMode}
        placeholder={label}
        {...props}
      />
    </Box>
  );
};
