import * as React from 'react'
import { Outlet, useMatch, useResolvedPath, useLocation } from 'react-router'
import { useNavigate } from 'react-router-dom'

import { Link as RouterLink } from 'react-router-dom'

import PubNub from 'pubnub'
import { PubNubProvider } from 'pubnub-react'

import { styled } from '@mui/material/styles'
import Box from '@mui/material/Box'
import Drawer from '@mui/material/Drawer'
import Toolbar from '@mui/material/Toolbar'
import List from '@mui/material/List'
import Popover from '@mui/material/Popover'
import Divider from '@mui/material/Divider'
import CssBaseline from '@mui/material/CssBaseline'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import ListItemButton from '@mui/material/ListItemButton'
import Button from '@mui/material/Button'
import Collapse from '@mui/material/Collapse'
import Typography from '@mui/material/Typography'
import CloseIcon from '@mui/icons-material/Close'
import { ReactComponent as LogoIcon } from '@assets/icons/logo.svg'
import AppBarDash from './AppBarDash'
import { useDispatch, useSelector } from 'react-redux'
import {
  conversationsRequest,
  selectConversations,
  selectUser
} from '@features/auth/authSlice'
import { jobCategoryRequest, selectCategories } from '@features/jobs/jobSlice'

import { ReactComponent as MessageIcon } from '@assets/icons/message.svg'
import { ReactComponent as SuitcaseIcon } from '@assets/icons/suitcase.svg'
import { ReactComponent as CandidatesIcon } from '@assets/icons/candidates.svg'
import { ReactComponent as PaperIcon } from '@assets/icons/paper.svg'
import { ReactComponent as BookmarkIcon } from '@assets/icons/bookmark.svg'
import { ReactComponent as PriceIcon } from '@assets/icons/price.svg'
import {
  updateCandidateList,
  selectCFilter
} from '@features/candidates/candidateSlice'
import { IconButton } from '@mui/material'
import Popup from './Popup'
import { database } from '@utils/firebase'

export const drawerWidth = 240

const Offset = styled('div')(({ theme }) => ({
  // ...theme.mixins.toolbar,
  height: '72px',
  minHeight: '72px'
}))

const Space = ({ active }) => (
  <Box sx={{ width: '20px', display: 'flex', justifyContent: 'flex-end' }}>
    <Typography sx={{ color: active ? '#FCA847' : '#8E908F' }}>-</Typography>
  </Box>
)

const Space1 = ({ active }) => (
  <Box sx={{ width: '20px', display: 'flex', justifyContent: 'flex-end' }}>
    <Typography sx={{ color: active ? '#FCA847' : '#8E908F' }}>-</Typography>
  </Box>
)

function CustomLink ({
  label,
  unread,
  to,
  Icon,
  start,
  item,
  sublink = false,
  selected = false,
  openSubRoles = false,
  cfilter,
  index,
  onClick = () => {},
  handleCategorySelect = () => {}
}) {
  const [anchorEl, setAnchorEl] = React.useState(null)

  const handleClick = event => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const open = Boolean(anchorEl)
  const id = open ? 'simple-popover' : undefined

  let resolved = useResolvedPath(to)
  let match = useMatch({ path: resolved.pathname, end: true }) && !sublink
  const location = useLocation()
  if (
    start &&
    to &&
    location.pathname.includes(to) &&
    location.pathname !== '/app/candidates/saved'
  ) {
    match = true
  }

  let color = match ? '#FCA847' : '#8E908F' //'#FFFFFF50';
  if (sublink && selected) {
    color = '#fff'
  }

  return (
    <>
      <div className='rowItem'>
        <ListItemButton
          key={label}
          sx={{ color }}
          component={RouterLink}
          to={to}
          onClick={onClick}
        >
          {Icon && (
            <ListItemIcon>
              <Icon fill={color} height={20} width={20} active={match} />
            </ListItemIcon>
          )}
          <ListItemText
            primary={
              <React.Fragment>
                <Typography
                  sx={{ display: 'inline' }}
                  component='span'
                  variant='body2'
                  color='text.primary'
                >
                  {label}
                </Typography>
              </React.Fragment>
            }
            sx={{
              //
              '& .MuiTypography-root': {
                fontWeight: '500',
                color
              }
            }}
          />
        </ListItemButton>
        {/* {label === 'Pricing' && (
          <Button
            aria-describedby={id}
            className='gPopup1'
            onClick={handleClick}
          >
            I
          </Button>
        )} */}
        {(label === 'V - Engineering' ||
          label === 'VIII - Legal' ||
          label === 'IX - Science') && (
          <Button
            aria-describedby={id}
            className='gPopup'
            onClick={handleClick}
          >
            G
          </Button>
        )}
      </div>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        style={{ width: '100%', top: 50, position: 'absolute' }}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left'
        }}
      >
        <div className='rowBetween'>
          {label === 'Pricing' ? (
            <Typography sx={{ p: 2, fontSize: 12, color: 'red' }}>
              For first time Lander account, card will not be charged until
              trial period expires
            </Typography>
          ) : (
            <Typography sx={{ p: 2 }}>
              {label === 'V - Engineering'
                ? 'Engineering'
                : label === 'VIII - Legal'
                ? 'Legal'
                : 'Science'}{' '}
              guide
            </Typography>
          )}
          <IconButton onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        </div>
        <div className='rowBetween'>
          <Popup label={label} />
        </div>
      </Popover>
      <Collapse
        in={openSubRoles === index}
        // sx={{ gridColumn: 'span 2' }}
      >
        {item?.subcategories?.map((c, subIndex) => (
          <CustomSubLink
            key={c?.name}
            sublink
            selected={cfilter?.roles === c?.name}
            label={c?.name}
            Icon={Space1}
            to='/app/candidates'
            onClick={() => handleCategorySelect(label, index, c?.name)}
          />
        ))}
      </Collapse>
    </>
  )
}

function CustomSubLink ({
  label,
  to,
  Icon,
  start,
  end,
  sublink = false,
  selected = false,
  onClick = () => {}
}) {
  let resolved = useResolvedPath(to)
  let match = useMatch({ path: resolved.pathname, end: true }) && !sublink
  const location = useLocation()
  if (
    start &&
    to &&
    location.pathname.includes(to) &&
    location.pathname !== '/app/candidates/saved'
  ) {
    match = true
  }

  let color = match ? '#FCA847' : '#8E908F' //'#FFFFFF50';
  if (sublink && selected) {
    color = '#fff'
  }
  return (
    <ListItemButton
      key={label}
      sx={{ color }}
      component={RouterLink}
      to={to}
      onClick={onClick}
    >
      <div style={{ width: 10 }} />
      {Icon && (
        <ListItemIcon>
          <Icon fill={color} height={20} width={20} active={match} />
        </ListItemIcon>
      )}
      <ListItemText
        primary={label}
        sx={{
          //
          '& .MuiTypography-root': {
            fontWeight: '500',
            color
          }
        }}
      />
    </ListItemButton>
  )
}

const pubnub = new PubNub({
  publishKey: 'pub-c-6e673cb0-89d8-4f5c-9157-792c45e56914',
  subscribeKey: 'sub-c-372c8218-7cdd-11ec-ae36-726521e9de9f',
  uuid: `user_`
})

function WrapperDashboard () {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const user = useSelector(selectUser)
  const conversations = useSelector(selectConversations)
  const [totalCount, setIsTotalCount] = React.useState(0)
  const categories = useSelector(selectCategories)
  const cfilter = useSelector(selectCFilter)
  const [openSub, setOpenSub] = React.useState(false)
  const [openSubRoles, setOpenSubRoles] = React.useState(false)
  const handleCategorySelect = (interests, index, roles) => {
    if (index && !roles) {
      setOpenSubRoles(index === openSubRoles ? false : index)
    }
    dispatch(updateCandidateList({ interests, roles }))
  }

  React.useEffect(()=>{
    getMessages()
  },[])

  const snapshotToArray = snapshot =>
    Object.entries(snapshot).map(e => Object.assign(e[1], { uid: e[0] }))

  const getMessages = async () => {
    try {
      database.ref(`Messages`).on('value', snapshot => {
        if (snapshot.val()) {
          const messages = snapshotToArray(snapshot.val())
          const filtered = messages?.filter(
            e => e?.receiverId === user?.id || e?.senderId === user?.id
          )
          let total = 0
          const unread = messages?.map(item => {
            if (item?.receiverId === user?.id && item?.receiverRead > 0) {
              total = total + item?.receiverRead
            }
            if (item?.senderId === user?.id && item?.senderRead > 0) {
              total = total + item?.senderRead
            }
          })
          setIsTotalCount(total)
        } else {
          handleChange('allList', [])
          handleChange('List', [])
        }
      })
    } catch (error) {
      console.log('err', error)
    }
  }
  const menu = {
    Company: [
      {
        label: 'Candidates',
        to: '/app/candidates',
        start: true,
        Icon: CandidatesIcon,
        showCategories: true,
        onClick: () => {
          handleCategorySelect('')
          setOpenSub(s => !s)
        }
      },
      // { label: 'Business', to: '/app/category/business', Icon: SuitcaseIcon },
      { label: 'My Job Post', to: '/app/jobs', Icon: PaperIcon },
      {
        label: `Message (${totalCount || 0})`,
        to: '/app/chat',
        Icon: MessageIcon,
        start: true
      },
      {
        label: 'Saved',
        to: '/app/candidates/saved',
        Icon: BookmarkIcon
      },
      { label: 'Pricing', to: '/app/price', Icon: PriceIcon }
    ],
    Employee: [
      {
        label: 'Candidates',
        to: '/app/candidates',
        start: true,
        Icon: CandidatesIcon,
        showCategories: true,
        onClick: () => {
          handleCategorySelect('')
          setOpenSub(s => !s)
        }
      },
      // { label: 'Business', to: '/app/category/business', Icon: SuitcaseIcon },
      { label: 'My Job Post', to: '/app/jobs', Icon: PaperIcon },
      {
        label: `Message (${totalCount || 0})`,
        to: '/app/chat',
        Icon: MessageIcon,
        start: true
      },
      {
        label: 'Saved',
        to: '/app/candidates/saved',
        Icon: BookmarkIcon
      }
    ],
    Candidate: [
      //
      { label: 'Jobs', to: '/app', Icon: SuitcaseIcon },
      {
        label: `Message (${totalCount || 0})`,
        to: '/app/chat',
        Icon: MessageIcon
      },
      { label: 'Saved', to: '/app/saved', Icon: BookmarkIcon }
      // { label: 'Pricing', to: '/app/price', Icon: PriceIcon },
    ]
  }
  React.useEffect(() => {
    dispatch(jobCategoryRequest())
    dispatch(conversationsRequest())
  }, [dispatch])

  React.useEffect(() => {
    const configPubNub = async () => {
      const uuid = `user_${user.id}`
      pubnub.setUUID(uuid)
      const { candidate, company } = user
      const {
        // Common
        photo, //: 'https://ideapros-llc-lander-30616.s3.amazonaws.com/media/company/profile/photos/mark-zuck-img_WQvsglJ.jpeg',
        city, //: 'San Francisco',
        zip_code, //: '92382',
        state, //: 'California'
        contact_number, //: '1230192039',

        //Company
        business_name, //: 'Meta',
        business_type, //: 'Marketing',
        position, //: 'CEO',
        // payment_method, //: '',

        //Candidate
        // resume, //: null,
        interests, //: [],
        current_company_industry, //: null,
        current_company_position, //: null,
        preferred_locations, //: [],
        // saved, //: [],
        currently_employed, //: null,
        current_company_name, //: null,
        video //: null,
        // resume_file, //: null
      } = company || candidate || {}
      try {
        const result = await pubnub.objects.setUUIDMetadata({
          uuid: uuid,
          data: {
            externalId: `${user?.id}`,
            name: `${user?.first_name} ${user?.last_name}`,
            profileUrl: photo,
            email: user?.email,
            custom: {
              type: user?.type,
              position: position || current_company_position,
              industry: current_company_industry || business_type,
              companyName: current_company_name || business_name,
              city,
              zip_code,
              state,
              contact_number
            }
          },
          include: {
            customFields: true
          }
        })
      } catch (status) {}
    }
    if (!user?.type) {
      navigate('/')
    }
    if (user && user.id) {
      // Using UUID from the config
      configPubNub()
    }
  }, [user, navigate])

  const openLandingPage = () => {
    // navigate('/')
    window.open('https://www.trylander.com/')
  }

  return (
    <PubNubProvider client={pubnub}>
      <Box sx={{ display: 'flex', height: '100%' }}>
        <CssBaseline />
        <AppBarDash />
        <Drawer
          sx={{
            width: drawerWidth,
            flexShrink: 0,
            '& .MuiDrawer-paper': {
              width: drawerWidth,
              boxSizing: 'border-box',
              backgroundColor: 'primary.main'
            }
          }}
          variant='permanent'
          anchor='left'
        >
          {/* <Offset /> */}
          <Toolbar
            disableGutters
            sx={{ height: '72px', justifyContent: 'center' }}
          >
            <Button onClick={() => openLandingPage()}>
              <LogoIcon />
            </Button>
          </Toolbar>
          <Divider sx={{ borderColor: 'rgba(255, 255, 255, 0.05)' }} />
          <List
            sx={{
              top: 0,
              overflowY: 'auto'
            }}
          >
            {user?.type &&
              menu[user.type].map(item => (
                <>
                  <CustomLink
                    key={item?.label}
                    unread={conversations?.unread}
                    {...item}
                  />
                  {item?.showCategories && (
                    <Collapse
                      in={openSub}
                      // sx={{ gridColumn: 'span 2' }}
                    >
                      {categories?.map((c, index) => (
                        <CustomLink
                          key={c?.name}
                          sublink
                          selected={cfilter?.interests === c?.name}
                          label={c?.name}
                          openSubRoles={openSubRoles}
                          item={c}
                          cfilter={cfilter}
                          setOpenSubRoles={setOpenSubRoles}
                          Icon={Space}
                          to='/app/candidates'
                          index={index + 1}
                          handleCategorySelect={handleCategorySelect}
                          onClick={() =>
                            handleCategorySelect(c?.name, index + 1)
                          }
                        />
                      ))}
                    </Collapse>
                  )}
                </>
              ))}
          </List>
        </Drawer>
        <Box
          component='main'
          sx={{
            display: 'flex',
            flexDirection: 'column',
            flexGrow: 1,
            bgcolor: 'background.default',
            p: 3,
            pt: 0
          }}
        >
          <Offset />
          <Outlet />
        </Box>
      </Box>
    </PubNubProvider>
  )
}

export default WrapperDashboard
