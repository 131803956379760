import * as React from 'react';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

function HeaderBar({ label, actions, actionsCenter }) {
  return (
    <Box
      sx={{
        //
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        my: 1,
      }}
    >
      <Box
        sx={{
          //
          flexGrow: 1,
        }}
      >
        {actionsCenter || (
          <Typography
            sx={{
              fontWeight: '500',
              fontSize: '1.5rem', // 24px
              p: '12px',
              lineHeight: '30px',
            }}
          >
            {label}
          </Typography>
        )}
      </Box>
      {/* <Box>{actionsCenter}</Box> */}
      <Box
        sx={
          {
            //
          }
        }
      >
        {actions}
      </Box>
    </Box>
  );
}

function WrapperContent({ label, children, actions, actionsCenter, sx = {} }) {
  // const navigate = useNavigate();
  // const user = useSelector(selectUser);

  return (
    <Box
      sx={{
        //
        display: 'flex',
        flexDirection: 'column',
        flexGrow: 1,
        // height: '100%',
      }}
    >
      <HeaderBar
        label={label}
        actions={actions}
        actionsCenter={actionsCenter}
      />
      <Box
        sx={{
          //
          // py: 2,
          flexGrow: 1,
          display: 'flex',
          ...sx,
        }}
      >
        {children}
      </Box>
    </Box>
  );
}

export default WrapperContent;
