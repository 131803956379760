import * as React from 'react';
import Skeleton from '@mui/material/Skeleton';
import Box from '@mui/material/Box';

export default function UILoading() {
  return (
    <Box sx={{ flex: 1 }}>
      <Skeleton animation="pulse" height="100%" />
    </Box>
  );
}
