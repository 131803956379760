import * as React from 'react'
import { useParams } from 'react-router'

import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import {
  selectUser,
  selectUserProfile,
  userProfileRequest
} from '@features/auth/authSlice'
import WrapperContent from '@components/WrapperContent'
import UICard from '@components/UICard'
import ProfileDetail from './ProfileDetail'
import { jobCategoryRequest } from '@features/jobs/jobSlice'

const PageProfileUser = () => {
  const dispatch = useDispatch()
  const [isDelete, setIsDelete] = React.useState(false)
  const user = useSelector(selectUser)
  const userProfile = useSelector(selectUserProfile)
  const { profileId = '' } = useParams()
  React.useEffect(async () => {
    if (profileId) {
      const res = await dispatch(userProfileRequest(profileId))
      if (res?.payload?.detail === 'Not found.') {
        setIsDelete(true)
      }
    } else if (user?.id) {
      dispatch(userProfileRequest(user?.id))
    }
    dispatch(jobCategoryRequest())
  }, [dispatch, profileId, user])
  const userView = profileId ? userProfile : user
  return (
    <WrapperContent label='Profile'>
      <UICard
        sx={{
          flexGrow: 1,
          display: 'flex',
          flexDirection: 'column'
        }}
      >
        {isDelete && (
          <p style={{ marginTop: 20, marginLeft: 20 }}>This user is deleted</p>
        )}
        {userView && (
          <ProfileDetail
            user={userView}
            currentUser={user}
            isProfile={!!profileId}
          />
        )}
      </UICard>
    </WrapperContent>
  )
}
export default PageProfileUser
