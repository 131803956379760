import * as React from 'react'
import { useNavigate } from 'react-router-dom'

import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'

import { ReactComponent as CheckedIcon } from '@assets/icons/checked.svg'
import UIButton from '@components/UIButton'
import UICard from '@components/UICard'
import JobSectionCategory from './JobSectionCategory'
import { useDispatch, useSelector } from 'react-redux'
import { unwrapResult } from '@reduxjs/toolkit'
import {
  clearJob,
  jobCategoryRequest,
  jobCreateRequest,
  selectJobDetail,
  jobDetailRequest
} from '@features/jobs/jobSlice'
import JobSectionName from './JobSectionName'
import JobSectionDescription from './JobSectionDescription'
import JobSectionBudget from './JobSectionBudget'
import PageConcratulationJob from './PageConcratulationJob'
import { handleError } from '@utils/common'
import { updateSnack } from '@features/util/utilSlice'
import { CircularProgress } from '@mui/material'

const SectionBox = ({ label, active, onClick }) => {
  return (
    <Box
      onClick={onClick}
      sx={{
        position: 'relative',
        width: '200px',
        height: '70px',
        borderRadius: '10px',
        // backgroundColor: 'pink',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        border: '1px solid rgba(0, 0, 0, 0.1)',
        cursor: 'pointer',
        userSelect: 'none',
        ...((active && {
          backgroundColor: 'wite',
          border: '1px solid rgba(0, 0, 0, 0.0)',
          boxShadow: '1px 3px 20px rgba(24, 20, 88, 0.06)'
        }) ||
          {})
      }}
    >
      <Typography
        sx={{
          //
          // py: 2,
          fontWeight: '500',
          fontSize: '1.125rem',
          // textAlign: 'center',
          color: active ? '#FF9141' : 'rgba(51, 51, 51, 0.5)'
        }}
      >
        {label}
      </Typography>
      {active && (
        <Box
          sx={{
            //
            position: 'absolute',
            top: 0,
            right: 0,
            p: 1
          }}
        >
          <CheckedIcon />
        </Box>
      )}
    </Box>
  )
}

export const ButtonJobContinue = ({ onSubmit, loading }) => (
  <Box
    sx={{
      flexGrow: 1,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'flex-end',
      my: 5
    }}
  >
    <UIButton
      color='primary'
      disabled={loading}
      sx={{
        py: 2,
        width: '185px',
        height: '49px'
      }}
      
      onClick={onSubmit}
    >
      {loading ? (
        <CircularProgress style={{ color: '#fff', width: 20, height: 20 }} />
      ) : (
        'Continue'
      )}
    </UIButton>
  </Box>
)

const steps = [
  { label: 'Category', Section: JobSectionCategory },
  { label: 'Job Name', Section: JobSectionName },
  { label: 'Description', Section: JobSectionDescription },
  { label: 'Budget', Section: JobSectionBudget }
]
const PageJobCreate = ({ jobId }) => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const jobDetail = useSelector(selectJobDetail)
  const [currentStep, setCurrentStep] = React.useState(0)
  const [loading, setLoading] = React.useState(false)
  const [isEdit, setIsEdit] = React.useState(false)
  const [files, setFiles] = React.useState({})
  React.useEffect(() => {
    if (jobId) {
      dispatch(jobDetailRequest(jobId)).then(() => {
        // setFiles(initFiles)
        setIsEdit(true)
        // setFiles(jobDetail?.files)
      })
    }
  }, [dispatch, jobId])

  React.useEffect(() => {
    //
    dispatch(clearJob())
    dispatch(jobCategoryRequest())
  }, [dispatch])
  const { Section } =
    currentStep < 4 ? steps[currentStep] : { Section: PageConcratulationJob }
  const handleContinue = () => {
    if (currentStep + 1 === 4) {
      // loading
      setLoading(true)
      dispatch(jobCreateRequest({ files, isEdit, jobId }))
        .then(unwrapResult)
        .then(data => {
          console.log('[dev]PageJobCreate::data', data)
          if (isEdit) {
            setLoading(false)
            navigate('/app/jobs')
          } else {
            setLoading(false)
            setCurrentStep(4)
          }
        })
        .catch(data => {
          handleError({
            data,
            alertMsg: errMsg =>
              dispatch(
                updateSnack({
                  open: true,
                  severity: 'error',
                  message: errMsg?.split(':')[1]
                })
              )
          })
          setLoading(false)
          console.log('[dev]PageJobCreate::catch::', data)
        })
    } else {
      setCurrentStep(s => s + 1)
    }
  }
  console.log('jobId', jobId)
  console.log('jobDetail', jobDetail)
  return (
    <UICard
      sx={{
        display: 'flex',
        flexDirection: 'column',
        flexGrow: 1,
        p: 2
      }}
    >
      {currentStep < 4 && (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between'
          }}
        >
          {steps.map(({ label }, idx) => (
            <SectionBox
              key={label}
              label={label}
              active={idx <= currentStep}
              onClick={() => {
                if (idx <= currentStep) {
                  setCurrentStep(idx)
                }
              }}
            />
          ))}
        </Box>
      )}
      {(currentStep === 2 && (
        <Section
          jobDetail={jobDetail}
          onSubmit={handleContinue}
          files={files}
          setFiles={setFiles}
        />
      )) || (
        <Section
          jobDetail={jobDetail}
          loading={loading}
          onSubmit={handleContinue}
        />
      )}
    </UICard>
  )
}

export default PageJobCreate
